import { StateCreator } from "zustand";

export type InsuranceState = {
  insurance: {
    insuranceId: string;
    insuranceLedgerId: string;
    insuranceSize: string;
    insurancePricePerMonth: string;
    insuranceDescription: string;
  };
};

export type InsuranceActions = {
  setInsuranceValue: (
    key: keyof InsuranceState["insurance"],
    value: any
  ) => void;
  setAllInsuranceValues: (values: Partial<InsuranceState>) => void;
  resetInsurance: () => void;
};

const initialState: InsuranceState = {
  insurance: {
    insuranceId: "",
    insuranceLedgerId: "",
    insuranceSize: "",
    insurancePricePerMonth: "",
    insuranceDescription: "",
  }
};

export const createInsuranceSlice: StateCreator<
  InsuranceState & InsuranceActions
> = (set) => ({
  insurance: {
    insuranceId: "",
    insuranceLedgerId: "",
    insuranceSize: "",
    insurancePricePerMonth: "",
    insuranceDescription: "",
  },
  setInsuranceValue: (key, value) =>
    set((state) => ({
      insurance: {
        ...state.insurance,
        [key]: value,
      },
    })),
  setAllInsuranceValues: (values: Partial<InsuranceState>) =>
    set((state) => ({ ...state, ...values })),
  resetInsurance: () => set(() => initialState),
});
