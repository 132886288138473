import { StateCreator } from "zustand";

export type DateState = {
  date: {
    id: number;
    startDate: Date;
  };
};

export type DateActions = {
  setDateValue: (key: keyof DateState["date"], value: any) => void;
  setAllDateValues: (values: Partial<DateState>) => void;
  resetDate: () => void;
};

const initialState: DateState = {
  date: {
    id: 0,
    startDate: new Date(),
  },
};

export const createDateSlice: StateCreator<DateState & DateActions> = (
  set
) => ({
  date: {
    id: 0,
    startDate: new Date(),
  },
  setDateValue: (key, value) =>
    set((state) => ({
      date: {
        ...state.date,
        [key]: value,
      },
    })),
  setAllDateValues: (values: Partial<DateState>) =>
    set((state) => ({ ...state, ...values })),
  resetDate: () => set(() => initialState),
});
