'use client';

import Image from 'next/image';

interface Props {
    shortName: string
}

export default function StorageSizeCardImage({ shortName }: Props) {

    const imageSrc = `/assets/img/storageSizes/storage_${shortName}.png`;
    const sizes = "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"; // Add sizes prop

    switch (shortName) {
        case "XS":
            return (
                <Image
                    src={imageSrc}
                    width={57}
                    height={87}
                    alt="Green Storage"
                    className={`max-h-[200px] lg:max-h-[120px] storage_unit_${shortName}`}
                    sizes={sizes}
                    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
            );
        case "S":
            return (
                <Image
                    src={imageSrc}
                    width={70}
                    height={125}
                    alt="Green Storage"
                    className={`max-h-[200px] lg:max-h-[120px] storage_unit_${shortName}`}
                    sizes={sizes}
                    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
            );
        case "M":
            return (
                <Image
                    src={imageSrc}
                    width={142}
                    height={151}
                    alt="Green Storage"
                    className={`max-h-[200px] lg:max-h-[120px] storage_unit_${shortName}`}
                    sizes={sizes}
                    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
            );
        case "L":
            return (
                <Image
                    src={imageSrc}
                    width={216}
                    height={177}
                    alt="Green Storage"
                    className={`max-h-[200px] lg:max-h-[120px] storage_unit_${shortName}`}
                    sizes={sizes}
                    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
            );
        case "XL":
            return (
                <Image
                    src={imageSrc}
                    width={222}
                    height={227}
                    alt="Green Storage"
                    className={`max-h-[200px] lg:max-h-[120px] storage_unit_${shortName}`}
                    sizes={sizes}
                    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
            );
        case "XXL":
            return (
                <Image
                    src={imageSrc}
                    width={368}
                    height={227}
                    alt="Green Storage"
                    className={`max-h-[200px] lg:max-h-[120px] storage_unit_${shortName}`}
                    sizes={sizes}
                    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
            );
        case "XXXL":
            return (
                <Image
                    src={imageSrc}
                    width={550}
                    height={378}
                    alt="Green Storage"
                    className={`max-h-[200px] lg:max-h-[120px] storage_unit_${shortName}`}
                    sizes={sizes}
                    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
            );
        case "C":
            return (
                <Image
                    src={imageSrc}
                    width={550}
                    height={378}
                    alt="Green Storage"
                    className={`max-h-[200px] lg:max-h-[120px] storage_unit_${shortName}`}
                    sizes={sizes}
                    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
            );
        case "KS":
            return (
                <Image
                    src={imageSrc}
                    width={550}
                    height={378}
                    alt="Green Storage"
                    className={`max-h-[200px] lg:max-h-[120px] storage_unit_${shortName}`}
                    sizes={sizes}
                    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
            );
        default:
            return (
                <Image
                    src={imageSrc}
                    width={142}
                    height={151}
                    alt="Green Storage"
                    className={`max-h-[200px] lg:max-h-[120px] storage_unit_${shortName}`}
                    sizes={sizes}
                    style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
            );
    }
}


