"use client";
import { eTextColor } from '@/app/models/enum/textColor';
import LinkElement from '../General/LinkElement';
import styles from './smalltextlink.module.scss';
import { iSmallTextLinkBlock } from '@/app/models/blockTypes/smallTextLinkBlock';
import { eButtonColorTheme } from '@/app/models/enum/eButtonColorTheme';
import { GetAbsoluteUrlClient, GetBackgroundColorClass } from '@/app/helpers/clientUtilityHelper';

interface Props {
    block: iSmallTextLinkBlock;
    translations: any;
    onPrev?: () => void;
    onNext?: () => void;
}


export default function SmallTextLink({ block, translations, onPrev, onNext }: Props) {
    const hasBackground = !!(block.properties.backgroundColor && block.properties.backgroundColor?.label.toLowerCase() !== "see through");
    const backgroundCssClass = GetBackgroundColorClass(block);
    const cssClasses = `${block.properties.showRoundedCorners && backgroundCssClass.indexOf("bg-see-through") === -1 ? 'rounded-3xl' : ''} ${backgroundCssClass}`;
    return (
        <div className={`flex flex-wrap h-full lg:h-[420px] lg:max-h-[420px] ${cssClasses}`}>
            {block.properties.image && block.properties.image[0] ? (
                <>
                    <div className={`w-full md:w-1/2 lg:max-h-[420px] flex flex-col justify-between ${hasBackground ? "p-10" : "pr-10"}`}>
                        <div>
                            <div className="relative flex justify-between items-start mb-2">
                                {block.properties.isNews && (
                                    <div className="chip mb-4">{translations.general.new}</div>
                                )}
                                {onPrev && onNext && (
                                    <div className="absolute top-0 right-0 space-x-4 flex lg:hidden">
                                        <button onClick={onPrev} className={`${styles.sliderButton} ${styles.sliderButtonLeft}`} />
                                        <button onClick={onNext} className={`${styles.sliderButton} ${styles.sliderButtonRight}`} />
                                    </div>
                                )}
                            </div>
                            <h2>{block.properties.title}</h2>
                            {block.properties.text && (
                                <p key="text">{block.properties.text}</p>
                            )}
                        </div>
                        {(block.properties.link || (onPrev && onNext)) && (
                            <div className="mt-auto flex justify-between items-center">
                                {block.properties.link && (
                                    <LinkElement
                                        title={`${translations.general.moreInformation} - ${block.properties.link.name}`}
                                        href={block.properties.link.route.path}
                                        styleType={eTextColor.Black}
                                        isButton={true}
                                        buttonColorTheme={eButtonColorTheme.DarkContrast}
                                    >
                                        {translations.general.moreInformation}
                                    </LinkElement>
                                )}
                                {onPrev && onNext && (
                                    <div className="space-x-4 hidden lg:flex">
                                        <button onClick={onPrev} className={`${styles.sliderButton} ${styles.sliderButtonLeft}`} />
                                        <button onClick={onNext} className={`${styles.sliderButton} ${styles.sliderButtonRight}`} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="w-full md:w-1/2 lg:max-h-[420px] flex items-center justify-center">
                        <img
                            src={GetAbsoluteUrlClient(block.properties.image[0].url)}
                            alt={block.properties.image[0].name}
                            className="w-full h-full object-cover rounded-3xl"
                        />
                    </div>
                </>
            ) : (
                <div className="w-full p-10 flex flex-col justify-between h-full">
                    <div>
                        <div className="relative flex justify-between items-start mb-2">
                            {block.properties.isNews && (
                                <div className="chip mb-4">{translations.general.new}</div>
                            )}
                            {onPrev && onNext && (
                                <div className="absolute top-0 right-0 space-x-4 flex lg:hidden">
                                    <button onClick={onPrev} className={`${styles.sliderButton} ${styles.sliderButtonLeft}`} />
                                    <button onClick={onNext} className={`${styles.sliderButton} ${styles.sliderButtonRight}`} />
                                </div>
                            )}
                        </div>
                        <h2>{block.properties.title}</h2>
                        {block.properties.text && (
                            <p key="text">{block.properties.text}</p>
                        )}
                    </div>
                    {(block.properties.link || (onPrev && onNext)) && (
                        <div className="mt-auto flex justify-between items-center">
                            {block.properties.link && (
                                <LinkElement
                                    title={`${translations.general.moreInformation} - ${block.properties.link.name}`}
                                    href={block.properties.link.route.path}
                                    styleType={eTextColor.Black}
                                    isButton={true}
                                    buttonColorTheme={eButtonColorTheme.DarkContrast}
                                >
                                    {translations.general.moreInformation}
                                </LinkElement>
                            )}
                            {onPrev && onNext && (
                                <div className="flex space-x-4 hidden lg:flex">
                                    <button onClick={onPrev} className={`${styles.sliderButton} ${styles.sliderButtonLeft}`} />
                                    <button onClick={onNext} className={`${styles.sliderButton} ${styles.sliderButtonRight}`} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div >
    );
}



