'use client';
import { clientLogError } from '@/app/helpers/clientLoggingHelper';
import { iDictionary } from '@/app/models/dictionary';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'

interface FormInputProps {
  name: string;
  label: string;
  errors?: iDictionary;
  placeholder?: string;
  className?: string;
  isMandatory?: boolean;
  helperText?: string;
  type?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | string, countryData?: any) => void;
  onValueChange?: (name: string, value: string, countryData?: any) => void;
  onError?: (name: string, error: string | string[]) => void;
  value?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement> | string) => void;
  country?: string;
  onMount?: (name: string, value: string, countryData?: any) => void;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  errors,
  placeholder,
  onChange,
  onKeyDown,
  onValueChange,
  onError,
  value,
  country,
  className = '',
  isMandatory = false,
  helperText = null,
  type = 'text',
  disabled = false,
  hideLabel = false,
  onMount }) => {
  const placeholderText = placeholder || label;
  const [countries, setCountries] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (errors && errors[name]) {
      setShowError(true);

      if (typeof errors[name] === 'string') {
        setErrorMessages([errors[name]]);
      } else {
        setErrorMessages(Object.values(errors[name]));
      }

      if (onError) {
        onError(name, errors[name]);
      }

    } else {
      setShowError(false);
    }
  }, [errors]);

  const inputProps = {
    id: name,
    name: name,
    type,
    'aria-label': label,
    placeholder: `${placeholderText}${isMandatory ? '*' : ''}`,
    disabled,
    onKeyDown,
    value: value,
  };

  useEffect(() => {
    if (type === 'tel') {
      const fetchCountries = async () => {
        try {
          const timespan = new Date().getTime();

          const response = await fetch(`/api/general/getCountries?timespan=${timespan}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });

          if (!response.ok) {
            clientLogError("FormInput.tsx", "fetch getCountries", `Network response was not ok: ${JSON.stringify(response)}`);
            throw new Error('Network response was not ok');
          }

          const fetchCountries = await response.json() as string[];
          const lowerCaseCountries = fetchCountries.map((country) => country.toLowerCase());
          setCountries(lowerCaseCountries);
          setLoading(false);
        } catch (error) {
          clientLogError("FormInput.tsx", "fetch getCountries", error);
          setLoading(false);
        }
      };

      fetchCountries();
    }
  }, [type]);

  if (type === 'tel') {
    return (
      <div className={className}>
        {!hideLabel && (
          <label htmlFor={name}>{`${label}${isMandatory ? '*' : ''}`}</label>
        )}
        {!loading && countries.length > 0 ? (
          <PhoneInput
            country={country}
            value={value}
            placeholder={`${placeholderText}${isMandatory ? '*' : ''}`}
            preferredCountries={countries}
            enableLongNumbers={true}
            onMount={(phone, countryData) => {
              if (onMount){
                onMount(name, phone, countryData);
              }
            }}
            onChange={(phone, countryData) => {
              if (phone.length > 0 && !phone.startsWith("+")) {
                phone = `+${phone}`;
              }

              if (onChange) {
                onChange(phone, countryData);
              }

              if (onValueChange) {
                onValueChange(name, phone, countryData);
              }
            }}
            inputProps={{
              name: name,
              required: isMandatory,
              disabled: disabled,
            }}
            onKeyDown={(e) => {
              if (onKeyDown) {
                onKeyDown(e);
              }
            }}
            inputClass={`phone-input ${showError ? "validation-error" : ""}`}
          />
        ) : (
          <div className="w-full h-[56px] flex justify-center items-center">
            <div className="smallSpinner"></div>
          </div>
        )}
        {helperText && (
          <span className="helper-text">{helperText}</span>
        )}
        {showError && (
          <span role="alert">{`${errorMessages.join(", ")}`}</span>
        )}
      </div>
    );
  } else {
    return (
      <div className={className}>
        {!hideLabel && (
          <label htmlFor={name}>{`${label}${isMandatory ? '*' : ''}`}</label>
        )}

        <input
          {...inputProps}
          className={`${showError ? "validation-error" : ""}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
              onChange(e);
            }

            if (onValueChange) {
              onValueChange(name, e.target.value);
            }
          }}
        />
        {helperText && (
          <span className="helper-text">{helperText}</span>
        )}
        {showError && (
          <span role="alert">{`${errorMessages.join(", ")}`}</span>
        )}
      </div>
    );
  }
};

export default FormInput;