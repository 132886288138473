"use client";
import { iSmallTextLinkBlock } from '@/app/models/blockTypes/smallTextLinkBlock';
import SmallTextLink from '../SmallTextLink/SmallTextLink';
import styles from './sliderblocksmalltextlink.module.scss';
import { useEffect, useState } from 'react';

interface Props {
    slides: iSmallTextLinkBlock[];
    slideDuration: number;
    translations: any;
}

export default function SliderBlockSmallTextLink({ slides, slideDuration, translations }: Props) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleNext();
        }, slideDuration * 1000);

        return () => clearTimeout(timer);
    }, [currentIndex, slideDuration]);

    return (
        <div className="relative">
            <div className={styles.sliderContainer}>
                <div
                    className={styles.sliderWrapper}
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {slides.map((slideBlock, index) => (
                        <div key={index} className={styles.slide}>
                            <div className={styles.slideContent}>
                                <SmallTextLink
                                    block={slideBlock}
                                    translations={translations}
                                    onPrev={handlePrev}
                                    onNext={handleNext}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}