'use client';

import { iMyPagesUserOrgData } from '@/app/models/myPages/myPagesUserOrgData';
import { iMyPagesUserPersonData } from '@/app/models/myPages/myPagesUserPersonData';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../Forms/FormInput';
import Modal from '../Modal/Modal';
import GDPR from '../GDPR/GDPR';
import Loader from '../Loader/Loader';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import LinkElement from '../General/LinkElement';
import { eChevronColor } from '@/app/models/enum/chevronColor';
import { eChevronPosition } from '@/app/models/enum/chevronPosition';
import { eLinkSize } from '@/app/models/enum/linkSize';
import { eTextColor } from '@/app/models/enum/textColor';
import { iMyPagesUser } from '@/app/models/myPages/myPagesUser';
import { clientLogError, clientLogInfo } from '@/app/helpers/clientLoggingHelper';
import { eButtonColorTheme } from '@/app/models/enum/eButtonColorTheme';

interface Props {
    profile: iMyPagesUser;
    country: string;
    changePaymentUrl: string;
    translations: any;
    payByInvoice: boolean;
    //   requestContext: iRequestContext;
}

interface iCardInformation {
    hiddenNumber: string;
    type: string;
    image: string;
    expireDate: string;
    expireWarning: boolean;
}

export default function CardInformation({ profile, country, changePaymentUrl, translations, payByInvoice }: Props) {
    const [cardInfo, setCardInfo] = useState<iCardInformation | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
   
    useEffect(() => {
        const getCurrentProfile = () => {
            return profile.userDataPerson ? profile.userDataPerson : profile.userDataOrganisation;
        }

        const currentProfile = getCurrentProfile();
        let cardTypeImage = "";

        if (currentProfile
            && currentProfile.creditCardType
            && currentProfile.hiddenCreditCardNumber
            && currentProfile.creditCardExpireDate
            && currentProfile.creditCardExpireWarning !== undefined) {

            switch (currentProfile.creditCardType.toLowerCase()) {
                case "mc":
                    cardTypeImage = "mc-logo.png";
                    break;
                case "amex":
                    cardTypeImage = "amex-logo.png";
                    break;
                case "maestro":
                    cardTypeImage = "maestro-logo.png";
                    break;
                case "visa":
                default:
                    cardTypeImage = "visa_logo.png";
                    break;
            }

            setCardInfo({
                hiddenNumber: currentProfile.hiddenCreditCardNumber,
                type: currentProfile.creditCardType,
                image: cardTypeImage,
                expireDate: currentProfile.creditCardExpireDate,
                expireWarning: currentProfile.creditCardExpireWarning
            });
        } else {
            setIsLoading(false);
        }
    }, [profile]);

    useEffect(() => {
        if (cardInfo) {
            setIsLoading(false);
        }
    }, [cardInfo]);


    return (
        <div className="rounded-3xl bg-white p-10 mb-10">
            <h2>{translations.pages.changePaymentPage.currentPaymentCard}</h2>
            {isLoading ? (
                <Loader loading={isLoading} useFullWindow={false} useNoBackground={true} />
            ) : (

                <div className="card-information flex items-center justify-between">
                    {!payByInvoice && (
                        <>
                        {cardInfo ? (
                            <div className="flex items-center">
                                <img src={`/assets/img/icons/${cardInfo.image}`} alt={cardInfo.type} className="mr-4" />
                                <div className="text-normal font-semibold mb-0-i">
                                    {cardInfo.hiddenNumber} {cardInfo.expireDate}
                                </div>
                                {cardInfo.expireWarning && (
                                    <div className="ml-4 font-warning font-semibold">{translations.pages.changePaymentPage.expiresSoon}</div>
                                )}
                            </div>
                        ) : (
                            <div className="flex items-center">
                                {translations.pages.changePaymentPage.noCardSaved}
                            </div>
                        )}
                        </>
                     )}
                    <LinkElement
                        title={cardInfo ? translations.pages.changePaymentPage.changePaymentCard : translations.pages.changePaymentPage.addCard}
                        buttonColorTheme={eButtonColorTheme.Green}
                        chevronPosition={eChevronPosition.None}
                        href={`${changePaymentUrl}?isCompany=${profile.userDataOrganisation ? true : false}&ui=${profile.userDataPerson ? profile.userDataPerson.customerId : profile.userDataOrganisation?.customerId}`}
                        isButton={true}
                    >
                        {cardInfo ? translations.pages.changePaymentPage.changePaymentCard : translations.pages.changePaymentPage.addCard}
                    </LinkElement>
                </div>
            )}
        </div>
    );
}
