import { StateCreator } from "zustand";

export type UnitState = {
  unit: {
    unitId: string;
    unitSizeId: string;
    unitSizeText: string;
    unitSizeTextShort: string;
    unitSizeM2: string;
  };
};

export type UnitActions = {
  setUnitValue: (key: keyof UnitState["unit"], value: any) => void;
  setAllUnitValues: (values: Partial<UnitState>) => void;
  resetUnit: () => void;
};

const initialState: UnitState = {
  unit: {
    unitId: "",
    unitSizeId: "",
    unitSizeText: "",
    unitSizeTextShort: "",
    unitSizeM2: "",
  },
};

export const createUnitSlice: StateCreator<UnitState & UnitActions> = (
  set
) => ({
  ...initialState,
  setUnitValue: (key, value) =>
    set((state) => ({
      unit: {
        ...state.unit,
        [key]: value,
      },
    })),
  setAllUnitValues: (values: Partial<UnitState>) =>
    set((state) => ({ ...state, ...values })),
  resetUnit: () => set(() => initialState),
});
