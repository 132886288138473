"use client";
import React, { useRef, useEffect, useState, useMemo } from "react";
import { formatDateToLocal, getBookableDateOptions } from "@/app/helpers/dateHelper";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import useStore from "@/stores/use-store";
import { useShallow } from "zustand/react/shallow";
import { iSelectItem } from "@/app/models/componentModels/selectItem";

interface DatepickerButtonProps {
  isEdit?: boolean;
  bookingDaysAhead: number;
  bookingTo?: Date;
  bookingFrom?: Date;
  todayDate: Date;
  culture: string;
}

export default function DateSelect({
  isEdit,
  bookingDaysAhead,
  bookingTo,
  bookingFrom,
  todayDate,
  culture,
}: DatepickerButtonProps) {
  const [dateOptions, setDateOptions] = useState<iSelectItem[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const today = todayDate;
  const dates: { id: number; date: Date }[] = [];
  if (bookingFrom && bookingFrom < today) {
    bookingFrom = today;
  }

  const dateOptionsTemp = getBookableDateOptions(today, bookingDaysAhead, bookingFrom ?? today, bookingTo ?? today, culture);

  const startDateId = doUseOrderStorageStore(
    useShallow((state) => state.date.id)
  );

  const setValues = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setAllDateValues;

  const updateDateValues = (id: number, startDate: Date) => {
    if (setValues) {
      setValues({
        date: {
          id,
          startDate,
        },
      });
    }
  };

    const createDateArray = () => {
    let id = 0;

    dateOptionsTemp.forEach(element => {
      dates.push({ id, date: element.date });
      id++;
    });

    return dates;
  };

    const dateArray = useMemo(
    () => createDateArray(),
    [dateOptionsTemp]
  );


  const setOptions = () => {
    const options = dateArray.map((date) => {
      const weekday = date.date.toLocaleDateString(culture, { weekday: "long" });
      const formattedDate = formatDateToLocal(date.date.toISOString(), culture);
      return {
        value: date.id.toString(),
        label: `${weekday} ${formattedDate}`,
      };
    });

    setDateOptions(options);

    const selectedDate = dateArray.find(
      (date) => date.id.toString() === startDateId.toString()
    );
    if (selectedDate) {
      let newSelectedValues = [...selectedValues];
      newSelectedValues = [selectedDate.id.toString()];
      setSelectedValues(newSelectedValues);
    }
  };

  useEffect(() => {
    setOptions();
  }, [isEdit, startDateId]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    let newSelectedValues = [...selectedValues];
    newSelectedValues = [value];

    const selectedDate = dates.find((date) => date.id.toString() === value);
    if (selectedDate && setValues) {
      updateDateValues(selectedDate.id, selectedDate.date);
    }
    setIsOpen(false);
    setSelectedValues(newSelectedValues);
  };

  const getSelectedLabels = () => {
    return dateOptions
      .filter((option) => selectedValues.includes(option.value))
      .map((option) => option.label)
      .join(", ");
  };

  return (
    <div
      className={`select-dropdown mb-6 ${isOpen ? "open" : "closed"}`}
      ref={dropdownRef}
    >
      <input
        type="text"
        readOnly
        className="capitalize"
        value={selectedValues.length ? getSelectedLabels() : ""}
        onClick={toggleDropdown}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-disabled={false}
      />
      <div
        className="arrow-alt"
        onClick={toggleDropdown}
        style={{
          backgroundImage: `url(/assets/img/icons/arrow_${
            isOpen ? "up" : "down"
          }.svg)`,
        }}
      />
      {isOpen && (
        <div className="options-wrapper adjusted-top">
          <ul className="options" role="listbox">
            {dateOptions.map((option, index) => (
              <li
                key={index}
                className="option capitalize"
                role="option"
                aria-selected={selectedValues.includes(option.value)}
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
