import { StateCreator } from "zustand";

export type InvoicePaymentState = {
  invoicePayment: {
    siteId: string;
    siteCode: string;
    sizeCodeId: string;
    paymentOrderId: string;
    invNumber: string;
    documentId: string;
    paymentMethod: string;
    paymentReference: string;
    customerId: string;
  };
};

export type InvoicePaymentActions = {
  setInvoicePaymentValue: (key: keyof InvoicePaymentState["invoicePayment"], value: any) => void;
  resetInvoicePayment: () => void;
};

const initialState: InvoicePaymentState = {
  invoicePayment: {
    siteId: "",
    siteCode: "",
    sizeCodeId: "",
    paymentOrderId: "",
    invNumber: "",
    documentId: "",
    paymentMethod: "",
    paymentReference: "",
    customerId: "",
  },
};
export const createInvoicePaymentSlice: StateCreator<InvoicePaymentState & InvoicePaymentActions> = (
  set
) => ({
  invoicePayment: {
    siteId: "",
    siteCode: "",
    sizeCodeId: "",
    paymentOrderId: "",
    invNumber: "",
    documentId: "",
    paymentMethod: "",
    paymentReference: "", 
    customerId: "",
  },
  setInvoicePaymentValue: (key, value) =>
    set((state) => ({
      invoicePayment: {
        ...state.invoicePayment,
        [key]: value,
      },
    })),
  resetInvoicePayment: () => set(() => initialState),
});
