"use client";

import React, { useEffect, useState } from "react";
import styles from "./campaignitem.module.scss";
import { iGetDiscountsBySitesRequestModel } from "@/app/models/apiModels/requestModels/getDiscountsBySitesRequestModel";
import { iDiscount } from "@/app/models/apiModels/Sizes/discount";
import Loader from "../Loader/Loader";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";
import { GetFormattedDate } from "@/app/helpers/clientUtilityHelper";

export interface Props {
    // key?: React.Key;
    campaignId: string;
    title?: string;
    text?: string;
    image: string;
    campaignEndDate: string;
    campaignStartDate: string;
    campaignLabel: string;
    className?: string;
    href: string;
    country: string;
    siteIdList: string[];
    translations: any;
}

export default function CampaignItem({ campaignId, title, text, image, campaignEndDate, campaignStartDate, campaignLabel, href, className, country, siteIdList, translations }: Props) {
    const endDate = GetFormattedDate(new Date(campaignEndDate).toISOString());
    const [bestDiscount, setBestDiscount] = useState<iDiscount | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchAndSetBestDiscount = async () => {
            const discount = await fetchBestDiscount();
            setBestDiscount(discount);
            setIsLoading(false);
        };

        fetchAndSetBestDiscount();
    }, []);

    const fetchBestDiscount = async () => {
        const requestModel: iGetDiscountsBySitesRequestModel = {
            country: country,
            siteIdList: siteIdList
        }

        try {
            const response = await fetch('/api/campaign/getDiscountsBySites', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestModel),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const bestDiscount = data.content as iDiscount;

            return bestDiscount || null;
        } catch (error) {
            clientLogError("CampaignItem.tsx", "fetchBestDiscount", error);
            return null;
        }
    };

    const renderDiscountText = () => {
        if (!bestDiscount) return "";

        if (bestDiscount.firstMonthDiscount) {
            return translations.pages.campaignListingPage.firstMonthDiscount;
        } else if (bestDiscount.discountPrice > 0 && bestDiscount.ordPrice > 0) {
            return (
                <>
                    {`${translations.general.fromCapital} `}  <span className={styles.ordinaryPrice}>{`${bestDiscount.ordPrice} ${translations.general.priceUnit}/${translations.general.shortMonth}`}</span>{` ${bestDiscount.discountPrice} ${translations.general.priceUnit}/${translations.general.shortMonth}`}
                </>
            );
        }

        return "";
    };

    return (
        <div className={`rounded-3xl ${styles.cardItem} bg-light-gs-green`}>
            <Loader loading={isLoading} useFullWindow={false} useNoBackground={true} />
            {!isLoading && (
                <a href={href} className={`${styles.cardItem} relative h-full  ${className}`}>
                    <div className="relative">
                        <div className="relative h-[200px]">
                            <img
                                src={image}
                                alt={title}
                                className={`rounded-t-3xl ${styles.image}`}
                            />
                        </div>
                        <div className="p-10 pb-6 flex flex-col h-full">
                            <h3>{title}</h3>
                            <div className="text-normal mb-0-i truncate-multiline-4">
                                {text}
                            </div>
                            <div className="text-normal mb-0-i">
                                {campaignLabel}
                            </div>
                            {bestDiscount && (
                                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                                    <div className={styles.diagonalBanner}>
                                        <div className="mt-5 mb-1-i h3">{renderDiscountText()}</div>
                                        <div className={`mb-1 ${styles.appliesUntil}`}>{`${translations.pages.campaignListingPage.appliesUntil} ${endDate}`}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </a>
            )}
        </div>
    );
}
