'use client';
import { useEffect } from "react";

const ScrollHandler = () => {
  useEffect(() => {
    const handleScroll = () => {
      const fixedBar = document.getElementById("fixedBar");
      const orderTopBar = document.getElementById("orderTopBar");
      if (fixedBar && orderTopBar) {
        const orderTopBarHeight = orderTopBar.offsetHeight;
        if (window.scrollY > orderTopBarHeight) {
          fixedBar.classList.add("translate-y-0");
          fixedBar.classList.remove("-translate-y-full");
        } else {
          fixedBar.classList.add("-translate-y-full");
          fixedBar.classList.remove("translate-y-0");
        }
      }
    };

    const fixedBar = document.getElementById("fixedBar");
    if (fixedBar) {
      const fixedBarStyle = window.getComputedStyle(fixedBar);
      if (fixedBarStyle.display !== "none") {
        window.addEventListener("scroll", handleScroll);
      }
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return null;
};

export default ScrollHandler;