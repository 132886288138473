import { StateCreator } from "zustand";

export type CreditCardState = {
  creditCard: {
    paymentOrderId: string;
    uniqueIdentifier: string;
  };
};

export type CreditCardActions = {
  setCreditCardValue: (key: keyof CreditCardState["creditCard"], value: any) => void;
  resetCreditCard: () => void;
};

const initialState: CreditCardState = {
  creditCard: {
    paymentOrderId: "",
    uniqueIdentifier: "",
  },
};
export const createCreditCardSlice: StateCreator<CreditCardState & CreditCardActions> = (
  set
) => ({
  creditCard: {
    paymentOrderId: "",
    uniqueIdentifier: ""
  },
  setCreditCardValue: (key, value) =>
    set((state) => ({
      creditCard: {
        ...state.creditCard,
        [key]: value,
      },
    })),
    resetCreditCard: () => set(() => initialState),
});
