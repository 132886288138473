import { StateCreator } from "zustand";

export type UserState = {
  user: {
    isCompany: boolean;
    isInvoice: boolean;
    forename: string;
    surname: string;
    // lastname: string;
    invoiceEmail: string;
    invoiceReference: string;
    personalNumber: string;
    streetAddress: string;
    postalCode: string;
    city: string;
    email: string;
    phoneNumber: string;
    customerId: string;
    companyName?: string;
    companyOrgNumber?: string;
  };
};

export type UserActions = {
  setUserValue: (key: keyof UserState["user"], value: any) => void;
  setAllUserValues: (values: Partial<UserState>) => void;
  resetUser: () => void;
};

const initialState: UserState = {
  user: {
    isCompany: false,
    isInvoice: false,
    forename: "",
    surname: "",
    invoiceEmail: "",
    invoiceReference: "",
    personalNumber: "",
    streetAddress: "",
    postalCode: "",
    city: "",
    email: "",
    phoneNumber: "",
    customerId: "",
    companyName: "",
    companyOrgNumber: "",
  }
};

export const createUserSlice: StateCreator<UserState & UserActions> = (set) => ({
  user: {
    isCompany: false,
    isInvoice: false,
    forename: "",
    surname: "",
    invoiceEmail: "",
    invoiceReference: "",
    personalNumber: "",
    streetAddress: "",
    postalCode: "",
    city: "",
    email: "",
    phoneNumber: "",
    customerId: "",
    companyName: "",
    companyOrgNumber: "",
  },
  setUserValue: (key, value) =>
    set((state) => ({
      user: {
        ...state.user,
        [key]: value,
      },
    })),
    setAllUserValues: (values: Partial<UserState>) =>
      set((state) => ({ ...state, ...values })),
    resetUser: () => set(() => initialState),
});