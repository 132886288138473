import { create } from "zustand";
import { persist } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";

import { createUnitSlice, UnitActions, UnitState } from "./slices/unit-slice";
import { createSiteSlice, SiteActions, SiteState } from "./slices/site-slice";
import { DateState, DateActions, createDateSlice } from "./slices/date-slice";
import {
  InsuranceState,
  InsuranceActions,
  createInsuranceSlice,
} from "./slices/insurance-slice";
import { UserState, UserActions, createUserSlice } from "./slices/user-slice";
import { SessionState, SessionActions, createSessionSlice } from "./slices/session-slice";
import {
  PromotionState,
  PromotionActions,
  createPromotionSlice,
} from "./slices/promotion-slice";
import {
  createPaymentSlice,
  PaymentActions,
  PaymentState,
} from "./slices/payment-slice";
import {
  createInvoicePaymentSlice,
  InvoicePaymentActions,
  InvoicePaymentState,
} from "./slices/invoicePayment-slice";
import {
  createCreditCardSlice,
  CreditCardActions,
  CreditCardState,
} from "./slices/creditcard-slice";
import {
  createValidationSlice,
  ValidationActions,
  ValidationState,
} from "./slices/validation-slice";

export type OrderState = UnitState &
  // SiteState &
  DateState &
  InsuranceState &
  UserState &
  SessionState &
  PaymentState &
  ValidationState &
  PromotionState &
  CreditCardState &
  InvoicePaymentState;
export type OrderActions = UnitActions &
  // SiteActions &
  DateActions &
  InsuranceActions &
  UserActions &
  SessionActions &
  PromotionActions &
  PaymentActions &
  ValidationActions &
  OrderStorageActions &
  CreditCardActions &
  InvoicePaymentActions;

interface OrderStorageActions {
  reset: () => void;
}

export const doUseOrderStorageStore = create<OrderState & OrderActions>()(
  subscribeWithSelector(
    persist(
      (set, get, store) => ({
        ...createUnitSlice(set, get, store),
        // ...createSiteSlice(set, get, store),
        ...createPaymentSlice(set, get, store),
        ...createValidationSlice(set, get, store),
        ...createDateSlice(set, get, store),
        ...createInsuranceSlice(set, get, store),
        ...createUserSlice(set, get, store),
        ...createSessionSlice(set, get, store),
        ...createPromotionSlice(set, get, store),
        ...createCreditCardSlice(set, get, store),
        ...createInvoicePaymentSlice(set, get, store),
        reset: () => {
          get().resetUnit();
          // get().resetSite();
          get().resetPayment();
          get().resetValidation();
          get().resetDate();
          get().resetInsurance();
          get().resetUser();
          get().resetPromotion();
          get().resetCreditCard();
          get().resetInvoicePayment();
        },
      }),
      { name: "orderstorage-store" }
    )
  )
);
