'use client';
import EmbeddedVideo from '@/app/components/EmbeddedVideo/EmbeddedVideo';
import Modal from '@/app/components/Modal/Modal';
import { iVideoBlock } from '@/app/models/blockTypes/videoBlock';
import { useState } from 'react';
import styles from './videoblock.module.scss';
import { GetAbsoluteUrlClient, GetBlockWrapperClasses } from '@/app/helpers/clientUtilityHelper';
import { logError } from '@/app/helpers/azureBlobLogger';
import { clientLogError } from '@/app/helpers/clientLoggingHelper';
import { usePathname } from 'next/navigation';

interface Props {
    block: iVideoBlock;
    translations: any;
}

export default function VideoBlock({ block, translations }: Props) {
    const pathname = usePathname();
    try {
        const [isModalOpen, setIsModalOpen] = useState(false);

        const handleOpenModal = () => {
            setIsModalOpen(true);
        };

        const handleCloseModal = () => {
            setIsModalOpen(false);
        };

        const imageUrl = GetAbsoluteUrlClient(block.properties?.image?.[0]?.url) ?? '';
        const imageAltText = block.properties?.imageDescription ?? '';

        return (
            <div className={`${GetBlockWrapperClasses(block)}`}>
                <div className={styles.imageBlock}>
                    <button onClick={handleOpenModal} className={styles.imageButton}>
                        <img
                            src={imageUrl}
                            alt={imageAltText}
                            className={`${styles.image} ${block.properties.showRoundedCorners ? "rounded-3xl" : ""}`}
                        />
                    </button>
                </div>
                <Modal closeTranslation={translations.general.close} isOpen={isModalOpen} key={block.id} showFrame={false} onClose={handleCloseModal}>
                    <EmbeddedVideo videoUrl={block.properties.videoUrl} className={block.properties.showRoundedCorners ? "rounded-3xl" : ""} />
                </Modal>
            </div >
        );
    } catch (error) {
        clientLogError("VideoBlock.tsx", 'Error when generating VideoBlock', `current url: ${pathname}`, 'Error', error);
    }
}
