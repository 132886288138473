'use client';
import React, { use, useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import ReactDOMServer from 'react-dom/server';
import { iMapMarker } from '@/app/models/mapMarker';
import Loader from '../Loader/Loader';


interface GoogleMapsProps {
    markers: iMapMarker[];
    zoom?: number;
    mapId?: string;
    autoAdjustZoom?: boolean;
    className?: string;
}

const GoogleMaps: React.FC<GoogleMapsProps> = ({ markers, className = "", zoom = 3, mapId = 'map_site', autoAdjustZoom = false }) => {
    const mapRef = useRef<google.maps.Map | null>(null);
    const markersRef = useRef<google.maps.Marker[]>([]);
    const [mapMarkers, setMapMarkers] = useState<iMapMarker[]>();
    const [mapMarkersLoading, setMapMarkersLoading] = useState<boolean>(true);

    useEffect(() => {
        setMapMarkersLoading(true);
        setMapMarkers(markers);
    }, [markers]);

    useEffect(() => {
        setMapMarkersLoading(false);
    }, [mapMarkers]);


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
    });

    const onLoad = (map: google.maps.Map) => {
        mapRef.current = map;
        centerMap(map);
    };

    const centerMap = (map: google.maps.Map) => {
        if (!mapMarkers) {
            return;
        }

        const bounds = new google.maps.LatLngBounds();
        mapMarkers.forEach(marker => {
            const latLng = new google.maps.LatLng(marker.latitude, marker.longitude);

            bounds.extend(latLng);
        });

        map.fitBounds(bounds);

        if (!autoAdjustZoom) {
            google.maps.event.addListenerOnce(map, 'idle', () => {
                if (mapMarkers.length === 1) {
                    map.setZoom(12); // Adjust this value as needed
                } else {
                    map.setZoom(zoom);
                }
            });
        } else if (mapMarkers.length === 1) {
            google.maps.event.addListenerOnce(map, 'idle', () => {
                map.setZoom(12); // Adjust this value as needed
            });
        }
    };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps</div>;
    }

    if (!mapMarkersLoading) {
        return (
            <GoogleMap
                mapContainerClassName={className}
                mapContainerStyle={{ height: '100%', width: '100%' }}
                zoom={zoom}
                onLoad={onLoad}
                options={{
                    mapTypeId: 'roadmap',
                    mapTypeControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                }}
            >
                {markers?.map((marker, index) => {
                    if (marker.markup) {
                        return (
                            <Marker
                                key={index}
                                position={{ lat: marker.latitude, lng: marker.longitude }}
                                onLoad={(marker) => {
                                    markersRef.current[index] = marker;
                                }}
                                icon={{
                                    url: '/assets/img/icons/icon_map_pin.svg', // Replace with your custom icon URL
                                    scaledSize: new google.maps.Size(50, 60), // Adjust the size as needed
                                }}
                                title={marker.title}
                                onClick={() => {
                                    const contentString = ReactDOMServer.renderToString(marker.markup);
                                    const infowindow = new google.maps.InfoWindow({
                                        content: contentString,
                                        ariaLabel: marker.title
                                    });
                                    infowindow.open(mapRef.current, markersRef.current[index]);
                                }}
                            />
                        )
                    } else {
                        return (
                            <Marker
                                key={index}
                                position={{ lat: marker.latitude, lng: marker.longitude }}
                                onLoad={(marker) => {
                                    markersRef.current[index] = marker;
                                }}
                                icon={{
                                    url: '/assets/img/icons/icon_map_pin.svg', // Replace with your custom icon URL
                                    scaledSize: new google.maps.Size(50, 60), // Adjust the size as needed
                                }}
                                title={marker.title}
                            />
                        )
                    }
                })}
            </GoogleMap>
        );
    } else {
        <Loader loading={true} useFullWindow={false} useNoBackground={true} />
    }


};

export default GoogleMaps;