"use client";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";
import { MapUnitFeatureAndUnit } from "@/app/models/apiModels/mapUnitFeatureAndUnit";
import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";
import { use, useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import Accordion from "../Accordion/Accordion";
import styles from "./unit.module.scss";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import { useShallow } from "zustand/react/shallow";
import useStore from "@/stores/use-store";
import FormRadiobutton from "../Forms/FormRadiobutton";
import FormCheckbox from "../Forms/FormCheckbox";
import { TwoDigitFormattedDate } from "@/app/helpers/dateHelper";
import { useRouter } from "next/navigation";

interface Props {
  pageData: iOrderStoragePage;
  translation: any;
  triggerRadioChange: boolean;
}

export default function FilterUnitSelection({ pageData, translation, triggerRadioChange }: Props) {
  const router = useRouter();
  const [selectedOption, setSelectedOption] = useState("warm");
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [isDirectAccessChecked, setIsDirectAccessChecked] = useState(false);
  const [isGroundFloorChecked, setIsGroundFloorChecked] = useState(false);
  const [mapUnitFeatureAndUnitResponse, setMapUnitFeatureAndUnitResponse] =
    useState<MapUnitFeatureAndUnit[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [forceToggleExpand, setForceToggleExpand] = useState<boolean>();

  // const unitSizeCode = pageData.searchParams.sizeCodeId?.toString();
  const siteId = pageData.searchParams.siteId?.toString();
  const orderFlowUrl =
    pageData.requestContext.configurations.orderingFlow.step1Page;
  const unitTranslation = translation.pages?.order?.unitInformation;
  const unit = pageData.properties.unit;
  const todaysDate = TwoDigitFormattedDate(
    pageData.properties.todaysDate.toISOString(),
    pageData.requestContext.culture
  );


  const toggleExpand = (isExpanding: boolean) => {
    if (isExpanding) {
      setIsLoading(true);

      const fetchGetMapUnitFeatureAndUnit = async () => {
        try {
          const response = await fetch("/api/order/getMapUnitFeatureAndUnit", {
            method: "POST",
            headers: {
              Country: pageData.requestContext.country,
            },
            body: JSON.stringify({
              siteId: siteId,
              unitSizeCode: unit?.SizeCodeId,
              onlyCurrentDate: false,
            }),
          });

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const mapUnitFeatureAndUnitResponse =
            (await response.json()) as MapUnitFeatureAndUnit[];
          // Filter out reserved units unless they are the currently selected unit
          const filteredResponse = mapUnitFeatureAndUnitResponse.filter(
            (mappedUnit) =>
              !mappedUnit.isReserved || mappedUnit.feature.unitID === unit?.Id
          );

          filteredResponse.sort((a, b) => {
            if (a.feature.unitID === unit?.Id) return -1;
            if (b.feature.unitID === unit?.Id) return 1;
            return 0;
          });


          setMapUnitFeatureAndUnitResponse(filteredResponse);
        } catch (error) {
          clientLogError(
            "FilterUnitSelection.tsx",
            "fetchGetMapUnitFeatureAndUnit",
            error
          );
        } finally {
          setIsLoading(false);
        }
      };
      fetchGetMapUnitFeatureAndUnit();
    }
  };

  const unReserveUnit = async (unitId: string, sizeId: number) => {
    try {
      const response = await fetch("/api/unit/unReserveUnit", {
        method: "POST",
        headers: {
          Country: pageData.requestContext.country,
        },
        body: JSON.stringify({
          unitId: unitId,
          sizeId: sizeId,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      setIsLoading(false);
    } catch (error) {
      clientLogError("FilterUnitSelection.tsx", "unReserveUnit", error);
    }
  };

  const setValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setUnitValue;

  const handleCheckboxChange = (mappedUnit: MapUnitFeatureAndUnit) => {
    setValue?.("unitId", mappedUnit.feature.unitID);
     if (unit?.Id) {
       unReserveUnit(unit.Id, unit.SizeId);
     }
   
    //  setForceToggleExpand(false);
    router.push(
      `${orderFlowUrl.url}?siteId=${siteId}&groupName=${pageData.properties.groupName}&unitId=${mappedUnit.feature.unitID}&reserve=true&edit=true`
    );
  };

  useEffect(() => {
    if (forceToggleExpand !== undefined) {
      console.log("forceToggleExpand", forceToggleExpand);
      setForceToggleExpand(undefined);
    }
}, [forceToggleExpand]);


  useEffect(() => {
    if (triggerRadioChange) {
      console.log("triggerRadioChange", forceToggleExpand);
      setForceToggleExpand(false);
    }
}, [triggerRadioChange]);

  const filteredUnits = mapUnitFeatureAndUnitResponse?.filter((mappedUnit) => {
    const isGroundFloorMatch =
      !isGroundFloorChecked || mappedUnit.feature.iS_GROUND_FLOOR === "1";
    const isElevatorFloorMatch =
      selectedCheckbox !== "elevatorFloor" ||
      mappedUnit.feature.iS_GROUND_FLOOR === "0";
    const isDirectAccessMatch =
      !isDirectAccessChecked || mappedUnit.feature.iS_DIRECT_ACCESS === "0";
    const isWarmMatch =
      selectedOption === "warm"
        ? mappedUnit.feature.iS_COLD === "0"
        : mappedUnit.feature.iS_COLD === "1";
    return (
      isGroundFloorMatch &&
      isElevatorFloorMatch &&
      isDirectAccessMatch &&
      isWarmMatch
    );
  });

  return (
    <Accordion
      title={translation.pages?.order?.unitInformation.selectSpecificUnit}
      translations={translation}
      onExpandCallback={toggleExpand}
      className={`rounded-[16px] ${styles.unitAccordion}`}
      forceToggle={forceToggleExpand}
    >
      <Loader
        loading={isLoading}
        useFullWindow={false}
        useNoBackground={true}
      />
      {!isLoading && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="rounded-3xl flex flex-col border border-gs-grey p-5">
            <FormRadiobutton
              name="cold-radio"
              groupName="warmcold-radio"
              checked={selectedOption === "cold"}
              value="cold"
              onChange={() => {
                setSelectedOption("cold");
              }}
              isReversed={true}
            >
              {unitTranslation.outsideUnit}
            </FormRadiobutton>
            <FormRadiobutton
              name="warm-radio"
              groupName="warmcold-radio"
              value="warm"
              checked={selectedOption === "warm"}
              onChange={() => {
                setSelectedOption("warm");
              }}
              isReversed={true}
            >
              {unitTranslation.heatedUnit}
            </FormRadiobutton>
          </div>
          <div className="rounded-3xl flex flex-col border border-gs-grey p-5 mt-2 lg:m-0 ">
            <FormCheckbox
              name="groundFloor-checkbox"
              value="groundFloor"
              checked={isGroundFloorChecked}
              onChange={() => {
                setIsGroundFloorChecked(!isGroundFloorChecked);
                if (!isGroundFloorChecked) {
                  setSelectedCheckbox("");
                }
              }}
              isReversed={true}
            >
              {unitTranslation.groundFloor}
            </FormCheckbox>
            <FormCheckbox
              name="elevatorFloor-checkbox"
              value="elevatorFloor"
              checked={selectedCheckbox === "elevatorFloor"}
              onChange={() => {
                setSelectedCheckbox(
                  selectedCheckbox === "elevatorFloor" ? "" : "elevatorFloor"
                );
                if (selectedCheckbox !== "elevatorFloor") {
                  setIsGroundFloorChecked(false);
                }
              }}
              isReversed={true}
            >
              {unitTranslation.elevatorPlane}
            </FormCheckbox>
            <FormCheckbox
              name="directAccess-checkbox"
              value="directAccess"
              checked={isDirectAccessChecked}
              onChange={() => setIsDirectAccessChecked(!isDirectAccessChecked)}
              isReversed={true}
            >
              {unitTranslation.directAccess}
            </FormCheckbox>
          </div>
          <div
            className={`rounded-3xl flex flex-col border border-gs-grey mt-2 !overflow-hidden max-h-64 lg:col-span-2`}
          >
            <div className="grid grid-cols-4 text-center">
              <label className="font-bold sticky bg-gs-white top-0 border-b border-gs-grey pt-5">
                {unitTranslation.unit}
              </label>
              <label className="font-bold sticky bg-gs-white top-0 border-b border-gs-grey pt-5">
                {unitTranslation.price}
              </label>
              <label className="font-bold sticky bg-gs-white top-0 border-b border-gs-grey pt-5">
                {unitTranslation.vacantFrom}
              </label>
              <div className="sticky bg-gs-white top-0 border-b border-gs-grey pt-5"></div>
              <div className={`col-span-4 ${styles.options}`}>
                {filteredUnits?.length === 0 ? (
                  <div>
                    {
                      pageData.requestContext.translations.pages?.order
                        ?.noOtherUnitsAvailable
                    }
                  </div>
                ) : (
                  filteredUnits?.map((mappedUnit, index) => (
                    <div
                      key={index}
                      className={`col-span-4 grid grid-cols-4 py-1 ${
                        index % 2 === 0 ? "bg-gs-green-50" : ""
                      }`}
                    >
                      <div>{mappedUnit.unitNumber}</div>
                      <div>{mappedUnit.price}</div>
                      <div>{todaysDate}</div>
                      {/* <div>{mappedUnit.date}</div> */}
                      <div>
                        <FormCheckbox
                          name={`unit-checkbox-${mappedUnit.unitNumber}`}
                          value={mappedUnit.unitNumber}
                          checked={mappedUnit.unitNumber === unit?.UnitNumber}
                          onChange={() => handleCheckboxChange(mappedUnit)}
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Accordion>
  );
}
