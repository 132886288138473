import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './modal.module.scss';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    closeTranslation: string;
    className?: string;
    showFrame?: boolean;
    blurBackground?: boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, closeTranslation, className = "", showFrame = true, blurBackground = true }) => {
    useEffect(() => {
        if (isOpen) {
            const scrollY = window.scrollY;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollY}px`;
            document.body.style.width = '100%'; // Ensure no horizontal scroll
        } else {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.width = ''; // Reset width
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    }, [isOpen]);

    useEffect(() => {
        const handleScroll = () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!isOpen) return null;

const onModalClose = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = ''; // Reset width
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    onClose();
};

    let cssClasses = `${styles.modalContent} ${className}`;
    let backdropClasses = `${styles.modalBackdrop} ${blurBackground ? styles.modalBackdropBlur : ''}`;

    return ReactDOM.createPortal(
        <div className={backdropClasses}>
           {showFrame ? (
                <div className={`${cssClasses} rounded-3xl p-10 bg-white`} style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    // overflow: 'hidden' // Prevent overflow
                }}>
                    <button
                        className={styles.modalClose}
                        onClick={onModalClose}
                        aria-label={closeTranslation}
                    ></button>
                    {children}
                </div>
            ) : (
                <div className={styles.modalContentNoFrame} >
                    <button
                        className={`${styles.modalClose} ${styles.modalCloseTopRight} ${styles.modalCloseOutline} rounded-full`}
                        onClick={onModalClose}
                        aria-label={closeTranslation}
                    ></button>
                    {children}
                </div>
            )}
        </div>,
        document.body
    );
};

export default Modal;