import { StateCreator } from "zustand";

export type SessionState = {
  session: {
    sessionId: string;
  };
};

export type SessionActions = {
  setSessionValue: (key: keyof SessionState["session"], value: any) => void;
  resetSession: () => void;
};

const initialState: SessionState = {
  session: {
    sessionId: "",
  }
};

export const createSessionSlice: StateCreator<SessionState & SessionActions> = (set) => ({
  session: {
    sessionId: "",
    
  },
  setSessionValue: (key, value) =>
    set((state) => ({
      session: {
        ...state.session,
        [key]: value,
      },
    })),
    resetSession: () => set(() => initialState),
});