import { StateCreator } from "zustand";

export type PaymentState = {
  payment: {
    reservedUnitId: string;
    siteId: string;
    siteCode: string;
    sizeCodeId: string;
    paymentOrderId: string;
    paymentOrderItemsId: string;
    amount: number;
    vatAmount: number;
    invNumber: string;
    invOutstanding: string;
  };
};

export type PaymentActions = {
  setPaymentValue: (key: keyof PaymentState["payment"], value: any) => void;
  resetPayment: () => void;
};

const initialState: PaymentState = {
  payment: {
    reservedUnitId: "",
    siteId: "",
    siteCode: "",
    sizeCodeId: "",
    paymentOrderId: "",
    paymentOrderItemsId: "",
    amount: 0,
    vatAmount: 0,
    invNumber: "",
    invOutstanding: "",
  },
};
export const createPaymentSlice: StateCreator<PaymentState & PaymentActions> = (
  set
) => ({
  payment: {
    reservedUnitId: "",
    siteId: "",
    siteCode: "",
    sizeCodeId: "",
    paymentOrderId: "",
    paymentOrderItemsId: "",
    amount: 0,
    vatAmount: 0,
    invNumber: "",
    invOutstanding: "",
  },
  setPaymentValue: (key, value) =>
    set((state) => ({
      payment: {
        ...state.payment,
        [key]: value,
      },
    })),
  resetPayment: () => set(() => initialState),
});
