'use client';

import React, { useState } from 'react';
import styles from './profileandlogout.module.scss';
import { iMyPagesUser } from '@/app/models/myPages/myPagesUser';
import Modal from '../Modal/Modal';
import Loader from '../Loader/Loader';
import ProfileChanger from '../ProfileChanger/ProfileChanger';
import LinkElement from '../General/LinkElement';
import { eButtonColorTheme } from '@/app/models/enum/eButtonColorTheme';
import { eChevronPosition } from '@/app/models/enum/chevronPosition';
import { clientLogError, clientLogInfo } from '@/app/helpers/clientLoggingHelper';

interface ProfileChangerProps {
    translations: any;
    userProfiles: iMyPagesUser[];
    onProfileChange: (selectedProfile: iMyPagesUser) => void;
    logoutRedirectUrl: string;
    country: string;
}

const ProfileAndLogout: React.FC<ProfileChangerProps> = ({ userProfiles, translations, onProfileChange, logoutRedirectUrl, country }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleLogout = async () => {
        try {
            setIsLoading(true);
            clientLogInfo("ProfileAndLogout.tsx", "onLogoutSubmit", "Logging out", "userProfiles", userProfiles);
            const response = await fetch("/api/user/logout", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Country: country,
                },
            });

            if (!response.ok) {
                throw new Error("Logout failed");
            }

            // Handle successful logout
            clientLogInfo("ProfileAndLogout.tsx", "onLogoutSubmit", "Logout successful");
            // Redirect to logout page
            const logoutUrl =
                logoutRedirectUrl ??
                `/${country}`;
            window.location.href = logoutUrl;
        } catch (error) {
            // Handle error
            clientLogError("ProfileAndLogout.tsx", "onLogoutSubmit", error);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 10000); // Delay hiding the loader for 5 seconds
        }
    }

    return (
        <div className="flex justify-end gap-4">
            <Loader loading={isLoading} useFullWindow={true} useNoBackground={false} />
            <ProfileChanger userProfiles={userProfiles} translations={translations} onProfileChange={onProfileChange} />
            {!isLoading && (
                <div>
                    <LinkElement
                        title={translations.general.logOut}
                        buttonColorTheme={eButtonColorTheme.DarkContrast}
                        chevronPosition={eChevronPosition.None}
                        isButton={true}
                        onClick={handleLogout}
                        className={styles.logoutButton}
                    >
                        <span className="hidden lg:inline">{translations.general.logOut} </span>
                        <img src="/assets/img/icons/icon_logout.svg" alt="Logout icon" className={styles.logoutIcon} />
                        <img src="/assets/img/icons/icon_logout_white.svg" alt="Logout icon" className={styles.logoutIconHover} />
                    </LinkElement>
                </div>
            )}

        </div>
    );
};

export default ProfileAndLogout;