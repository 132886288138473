"use client";
import { iAreaSimpleFlatModel } from '@/app/models/apiModels/areaSimpelFlat';
import { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import FacilitySizesInArea from './FacilitySizesInArea';
import { iGetSitesBySizeRequestModel } from '@/app/models/apiModels/requestModels/getSitesBySizeRequestModel';
import { clientLogError, clientLogInfo } from '@/app/helpers/clientLoggingHelper';

interface Props {
    country: string;
    sizeCodeId: string;
    groupName: string;
    translations: any;
    countdownTimer: number;
    orderUrl: string;
}


export default function SizeByArea({ country, sizeCodeId, groupName, translations, countdownTimer, orderUrl }: Props) {
    const [coordinates, setCoordinates] = useState<{ latitude: number; longitude: number } | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [areas, setAreas] = useState<iAreaSimpleFlatModel[]>([]);
    const [isCoordinatesRequestPending, setIsCoordinatesRequestPending] = useState<boolean>(true);

    useEffect(() => {
        if ("geolocation" in navigator) {
            try {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setCoordinates({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        });
                        setIsCoordinatesRequestPending(false);
                    },
                    (error) => {
                        clientLogError("SizeByArea.tsx", "error inside when fetching coordinates", error);
                        setCoordinates(null);
                        setIsCoordinatesRequestPending(false);
                    }
                );
            } catch (error) {
                clientLogError("SizeByArea.tsx", "useEffect fetching coordinates", error);
                setCoordinates(null);
                setIsCoordinatesRequestPending(false);
            }
        } else {
            console.log("geolaction does not exists");
            setCoordinates(null);
            setIsCoordinatesRequestPending(false);
        }
    }, []);

    useEffect(() => {
        // Fetch areas useEffect
        const fetchAreas = async () => {
            try {
                const requestModel: iGetSitesBySizeRequestModel = {
                    groupName: groupName,
                    coordinates: coordinates
                };

                clientLogInfo("SizeByArea.tsx", "fetchAreas", `Request model: ${JSON.stringify(requestModel)}`);

                const response = await fetch('/api/site/getSitesBySize', {
                    method: 'POST',
                    headers: {
                        'Country': country,
                    },
                    body: JSON.stringify(requestModel),
                });

                if (!response.ok) {
                    clientLogError("SizeByArea.tsx", "fetchAreas", `Network response was not ok: ${JSON.stringify(response)}`);
                    throw new Error(`Network response was not ok ${JSON.stringify(response)}`);
                }

                clientLogInfo("SizeByArea.tsx", "fetchAreas", `Response ok: ${JSON.stringify(response)}`);

                const fetchedAreas = await response.json() as iAreaSimpleFlatModel[];

                setAreas(fetchedAreas);

            } catch (error) {
                clientLogError("SizeByArea.tsx", "fetchAreas", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (coordinates || (coordinates === null && isCoordinatesRequestPending) || (coordinates === null && !isCoordinatesRequestPending)) {
            setIsLoading(true);
            setAreas([]);
            fetchAreas();
        }
    }, [coordinates, isCoordinatesRequestPending]);

    return (
        <div className={`areaContainer`}>
            {isLoading ? (
                <Loader loading={isLoading} useFullWindow={false} useNoBackground={true} />
            ) : (
                <>
                    {areas && areas.map((area, index) => (
                        <FacilitySizesInArea
                            key={index}
                            area={area}
                            groupName={groupName}
                            translations={translations}
                            country={country}
                            sizeCodeId={sizeCodeId}
                            countdownTimer={countdownTimer}
                            orderUrl={orderUrl}
                            expandOnLoad={index === 0} />
                    ))}
                </>
            )}
        </div>
    );
}



