'use client';
import React from 'react';
import { iFacilitySimpelModel } from '@/app/models/apiModels/facilitySimpel';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
import FacilityMapMarkerComponent from '../GoogleMaps/FacilityMapMarkerComponent';

interface FacilitiesMapProps {
    facilities: iFacilitySimpelModel[];
    requestContext: iRequestContext
    zoom: number;
    mapId?: string;
}

const FacilitiesMap: React.FC<FacilitiesMapProps> = ({ facilities, requestContext, zoom, mapId = 'map_site' }) => {
    const markers = facilities.map(facility => ({
        title: facility.name,
        latitude: facility.latitude,
        longitude: facility.longitude,
        markup: (<FacilityMapMarkerComponent
                title={facility.name}
                streetAddress={facility.streetAddress}
                city={facility.city}
                lowestPrice={`${requestContext.translations.general.from} ${facility.lowestPrice} ${requestContext.translations.general.priceUnit}/${requestContext.translations.general.shortMonth}`}
                linkText={`${requestContext.translations.general.goTo} ${facility.name}`}
                linkUrl={facility.path}
            />)
    }));

    return (
        <GoogleMaps className="rounded-3xl" markers={markers} zoom={zoom} mapId={mapId} autoAdjustZoom={true} />
    );
};

export default FacilitiesMap;