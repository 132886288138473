import React from 'react';
import LinkElement from '../General/LinkElement';
import { eTarget } from '@/app/models/enum/target';
import { eLinkSize } from '@/app/models/enum/linkSize';
import { eChevronPosition } from '@/app/models/enum/chevronPosition';
import { eChevronColor } from '@/app/models/enum/chevronColor';
import styles from './googlemaps.module.scss';

interface MapMarkerComponentProps {
    title: string;
    streetAddress: string;
    city: string;
    lowestPrice?: string;
    linkText: string;
    linkUrl: string;
}

const FacilityMapMarkerComponent: React.FC<MapMarkerComponentProps> = ({ title, streetAddress, city, lowestPrice, linkText, linkUrl }) => {
    return (
        <div className={styles.mapMarkerContainer}>
            <h3>{title}</h3>
            <div>
                <div className={styles.streetAddress}>{streetAddress}</div>
                <div className={styles.city}>{city}</div>
                {lowestPrice && (
                    <div className={styles.lowestPrice}>{lowestPrice}</div>
                )}
            </div>
            <LinkElement
                href={linkUrl}
                isStandalone={true}
                target={eTarget.Self}
                linkSize={eLinkSize.Medium}
                chevronPosition={eChevronPosition.Left}
                chevronColor={eChevronColor.Green}
                title={linkText}>
                {linkText}
            </LinkElement>
        </div>
    );
};

export default FacilityMapMarkerComponent;