"use client";
import { iSite } from "@/app/models/apiModels/site";
import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";
import { iUnitFeature } from "@/app/models/unitFeature";
import { iUnitObject } from "@/app/models/unitObject";
import styles from "./facility-information.module.scss";
import React from "react";
import { iFacilityFeature } from "@/app/models/facilityFeature";
import Image from "next/image";

interface UnitInformationProps {
  translation: any;
  site: iSite;
  siteProperties: iSiteProperties;
  facilityFeatures: iFacilityFeature[]
}

export default function FacilityInformation({
  translation,
  site,
  siteProperties,
  facilityFeatures
}: UnitInformationProps) {
  return (
    <>
      <h4 className="font-bold">{translation.facility}</h4>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-2">
        <div className="col-span-1	w-full">
          <h4 className="font-bold">{site?.name}</h4>
          <p>
            {site?.address1}, {site?.town}
          </p>

          <h4 className="font-bold pt-2">
            {translation.pages?.order?.facilityOpeningHours}
          </h4>
          <p>{siteProperties?.openingHours}</p>
        </div>
        <div className="col-span-1 w-full">
          <div className="grid grid-cols-1 gap-4 items-start auto-rows-min ">
            {facilityFeatures && facilityFeatures.map((feature: iFacilityFeature, index: number) => {
              if (!feature) return null;

              return (
                <div
                  className={`py-2 px-4 flex items-center justify-start flex-shrink-0 ${styles.featureItem}`}
                  key={index}
                >
                  <div className="flex-shrink-0">
                    <Image
                      src={feature.image}
                      width={33}
                      height={33}
                      alt={feature.text}
                    />
                  </div>
                  <div className="ml-4 text-normal mb-0-i font-bold md:text-md lg:text-md">
                    {feature.text}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
