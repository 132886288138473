"use client";
import Modal from "@/app/components/Modal/Modal";
import DateSelect from "@/app/components/Order/DateSelect";
import FacilityInformation from "@/app/components/Order/FacilityInformation";
import FilterUnitSelection from "@/app/components/Order/FilterUnitSelection";
import InsuranceSelect from "@/app/components/Order/InsuranceSelect";
import PoliciesCheckbox from "@/app/components/Order/PoliciesCheckbox";
import UnitInformation from "@/app/components/Order/UnitInformation";
import UnitSelection from "@/app/components/Order/UnitSelection";
import UserInformationForm from "@/app/components/Order/UserInformationForm";
import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import styles from "./orderstoragestep1orderstoragepage.module.scss";
import NoUnitModal from "@/app/components/Order/noUnitModal";

interface OrderStoragePageProps {
  pageData: iOrderStoragePage;
}

export default function OrderStorageStep1OrderStorage({
  pageData,
}: OrderStoragePageProps) {
  const router = useRouter();
  const handleCloseModal = () => {
    router.push(
      `${pageData.requestContext.configurations.orderingFlow.chooseFacilityPage.url}?sizeCodeId=${pageData.properties.sizeCodeId}&groupName=${groupName}`
    );
  };

  const [triggerRadioChange, setTriggerRadioChange] = useState(false);
  const siteData = pageData.properties.site;
  const sitepropData = pageData.properties.siteProperties;
  const siteInsurance = pageData.properties.siteInsurances;
  const facilityFeatures = pageData.properties.facilityFeatures;
  const isEdit = pageData.searchParams.edit === "true" || false;
  const translation = pageData.requestContext.translations;
  const siteUnit = pageData.properties.unit;
  const groupName = pageData.searchParams.groupName?.toString();
  let isAllowedRedirect = false;

  const reset = doUseOrderStorageStore(useShallow((state) => state.reset));
  const validation = doUseOrderStorageStore(
    useShallow((state) => state.validation)
  );

  useEffect(() => {
    isAllowedRedirect = validation.isAllowedRedirect;
  }, [validation]);

  useEffect(() => {
    if (isAllowedRedirect) return;

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };
    const handelPageHide = () => {
      fetch(`/api/unit/unReserveUnit`, {
        keepalive: true,
        method: "POST",
        headers: {
          "content-type": "application/json",
          Country: pageData.requestContext.country,
        },
        body: JSON.stringify({
          unitId: siteUnit?.Id,
          sizeId: siteUnit?.SizeId,
        }),
      });
      // reset();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("pagehide", handelPageHide);

    return () => {
      window.removeEventListener("pagehide", handelPageHide);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isAllowedRedirect]);

  if (!siteUnit) {
    return (
      <div className="px-6 lg:px-0">
        <div className="justify-items-center bg-gs-white rounded-3xl p-4 lg:p-10 border border-gs-black-300">
          <div role="status" className="max-w-sm animate-pulse">
            <div className="h-2.5 bg-gs-grey rounded-full w-48 mb-4"></div>
            <div className="h-2 bg-gs-grey rounded-full max-w-[360px] mb-2.5"></div>
            <div className="h-2 bg-gs-grey rounded-full mb-2.5"></div>
            <div className="h-2 bg-gs-grey rounded-full max-w-[330px] mb-2.5"></div>
            <div className="h-2 bg-gs-grey rounded-full max-w-[300px] mb-2.5"></div>
            <div className="h-2 bg-gs-grey rounded-full max-w-[360px]"></div>
            <span className="sr-only">Loading...</span>
          </div>
          <Modal
            isOpen={true}
            onClose={handleCloseModal}
            closeTranslation={translation.general.closePopup}
            className="w-500-responsive"
            blurBackground={true}
          >
            <NoUnitModal onClose={handleCloseModal} pageData={pageData} />
          </Modal>
        </div>
      </div>
    );
  }

  const changeRadioButtons = () => {
    setTriggerRadioChange(true);
  };

  useEffect(() => {
    if (triggerRadioChange) {
      setTriggerRadioChange(false);
    }
  }, [triggerRadioChange]);

  useEffect(() => {
    if (!isEdit) {
      reset();
    }
  }, [reset]);

  return (
    <>
      <div className="px-6 lg:px-0">
        <div className="justify-items-center bg-gs-white rounded-3xl p-4 lg:p-10 border border-gs-black-300">
          <div className="w-full">
            <div>
              <h3 className="text-md font-bold">
                {translation.general?.storageUnit ?? "Storage"}
              </h3>
              <UnitSelection
                translation={translation}
                pageData={pageData}
                isEdit={isEdit}
                onChange={changeRadioButtons}
              />
            </div>

            <FilterUnitSelection
              translation={translation}
              pageData={pageData}
              triggerRadioChange={triggerRadioChange}
            />

            <div className="bg-gs-grey rounded-lg p-6 mt-6">
              <p className="text-md font-bold mb-0">
                {translation.pages?.order?.choosenUnit}
              </p>
              <UnitInformation
                pageData={pageData}
                translation={translation}
                isEdit={isEdit}
              />
            </div>

            {siteData && sitepropData && (
              <div className="pt-6">
                <h4 className="font-bold">
                  {translation.general?.facility ?? "Facility"}
                </h4>
                <FacilityInformation
                  translation={translation}
                  site={siteData}
                  siteProperties={sitepropData}
                  facilityFeatures={facilityFeatures}
                />
              </div>
            )}

            <div className="pt-6">
              <h3 className="font-bold">
                {translation.pages?.order?.startDate ?? "Start date"}
              </h3>
              <DateSelect
                bookingDaysAhead={parseInt(
                  pageData.properties.bookingAheadDays.value
                )}
                culture={pageData.requestContext.language}
                todayDate={pageData.properties.todaysDate}
                bookingTo={pageData.properties.unit?.BookingTo}
                bookingFrom={pageData.properties.unit?.BookingFrom}
                isEdit={isEdit}
              />
            </div>

            <div className="pt-6">
              <h3 className="font-bold">
                {translation.pages?.order?.insurance ?? "Insurance"}
              </h3>
              <InsuranceSelect
                siteInsurance={siteInsurance}
                translation={translation}
                isEdit={isEdit}
              />
            </div>
          </div>
        </div>

        <div className=" justify-items-center bg-gs-white rounded-3xl p-6 lg:p-10 mt-8">
          <div className="w-full">
            <h3 className="font-bold">
              {translation.pages?.order?.yourInformation ?? "Your information"}
            </h3>
            <UserInformationForm
              translation={translation}
              isEdit={isEdit}
              isloggedin={pageData.properties.isloggedin ?? false}
              country={pageData.requestContext.country}
            />
          </div>

          {/* <div className=" justify-items-center bg-gs-white rounded-3xl p-6 lg:p-10 mt-8">
    <h3 className="font-bold">
      {translation.pages?.order?.couponCode ?? "Coupon code"}
    </h3>
    <PromotionInput translation={translation} />
  </div>  */}
        </div>
        <div className=" justify-items-center bg-gs-white rounded-3xl p-10 mt-8">
          <div className="w-full">
            <h3 className="font-bold">
              {translation.pages?.order?.policiesAndApprovals ??
                "Policies and approvals"}
            </h3>
            <PoliciesCheckbox
              translation={translation}
              isEdit={isEdit}
              insuranceTermsLink={
                pageData.requestContext.configurations.orderingFlow
                  .insuranceTermsPage
              }
              integrityPolicyLink={
                pageData.requestContext.configurations.orderingFlow
                  .integrityPolicyPage
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
