"use client";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import useStore from "@/stores/use-store";
import React from "react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useShallow } from "zustand/react/shallow";
import FormInput from "../Forms/FormInput";
import { getLangAndCulture } from "@/app/helpers/contextHelper";
import { NextResponse } from "next/server";
import { userInfo } from "os";
import { set } from "lodash";
import { Userdata } from "@/services/microservices/user.service";
import FormCheckbox from "../Forms/FormCheckbox";
import { Userprops } from "@/app/models/apiModels/userprops";
import { iDictionary } from "@/app/models/dictionary";
import { error } from "console";

export default function UserInformationForm({
  translation,
  isEdit,
  isloggedin,
  country,
}: {
  translation: any;
  isEdit: boolean;
  isloggedin: boolean;
  country: string;
}) {
  let user = doUseOrderStorageStore(useShallow((state) => state.user));
  let userdata: Userprops[] | null = null;

  const [activeButton, setActiveButton] = useState<"person" | "company">(
    "person"
  );
  const [selectedCheckbox, setSelectedCheckbox] = useState(user.isInvoice);
  const [disableCompanyNumber, setDisableCompanyNumber] = useState(false);
  const [errorList, setErrorList] = useState<iDictionary>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [phoneCountryCode, setPhoneCountryCode] = useState<string>();
  const [personalNumberDirty, setPersonalNumberDirty] = useState(false);
  const [companyNameDirty, setCompanyNameDirty] = useState(false);
  const [companyOrgNumberDirty, setCompanyOrgNumberDirty] = useState(false);
  const [invoiceEmailDirty, setInvoiceEmailDirty] = useState(false);
  const [streetAddressDirty, setStreetAddressDirty] = useState(false);
  const [postalCodeDirty, setPostalCodeDirty] = useState(false);
  const [cityDirty, setCityDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [phoneNumberDirty, setPhoneNumberDirty] = useState(false);




  const setValidationValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setValidationValue;

  const setValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setUserValue;

  const setallValues = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setAllUserValues;

  useEffect(() => {
    if (isEdit) {
      if (user.isCompany) {
        setActiveButton("company");
      }
      if (!user.isCompany) {
        setActiveButton("person");
      }
    }
  }, [isEdit, user.isCompany]);

  const updateValues = (
    isCompany: boolean,
    isInvoice: boolean,
    forename: string,
    surname: string,
    invoiceEmail: string,
    invoiceReference: string,
    personalNumber: string,
    streetAddress: string,
    postalCode: string,
    city: string,
    email: string,
    phoneNumber: string,
    customerId: string,
    companyName?: string,
    companyOrgNumber?: string
  ) => {
    if (setallValues) {
      setallValues({
        user: {
          isCompany,
          isInvoice,
          forename,
          surname,
          invoiceEmail,
          invoiceReference,
          personalNumber,
          streetAddress,
          postalCode,
          city,
          email,
          phoneNumber,
          customerId,
          companyName,
          companyOrgNumber,
        },
      });
    }
  };


  function isValidCPR(cpr: string): boolean {
    if (cpr.length !== 10) return false;

    const day = parseInt(cpr.substring(0, 2), 10);
    const month = parseInt(cpr.substring(2, 4), 10);
    const year = parseInt(cpr.substring(4, 6), 10);

    const currentYear = new Date().getFullYear();
    const fullYear = year + (year <= currentYear % 100 ? 2000 : 1900);

    const birthDate = new Date(fullYear, month - 1, day);

    var age = new Date().getFullYear() - birthDate.getFullYear();
    const monthDiff = new Date().getMonth() - birthDate.getMonth();
    const dayDiff = new Date().getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age >= 18;
  }

  const addError = (tempErrorList: iDictionary, key: string, message: string) => {
    if (!tempErrorList[key]) {
      tempErrorList[key] = [message];
    } else {
      const newSpecifiedErrorList = tempErrorList[key] as string[];
      tempErrorList[key] = [...newSpecifiedErrorList, message];
    }
    return tempErrorList;
  };

  const validatePersonalNumber = (personalNumber: string) => {
    let tempErrorList = {};
    if (personalNumber.length === 0) {
      if (personalNumberDirty) {
        return addError(tempErrorList, "personalNumber", translation.pages?.order.form.personalNumberError);
      }
    }

    if (personalNumber.length !== 10 && personalNumber.length !== 0) {
      if (personalNumberDirty) {
        return addError(tempErrorList, "personalNumber", translation.pages?.order.form.personalNumberLengthError);
      }
    }

    if (!/^\d+$/.test(personalNumber) && personalNumber.length === 10) {
      if (personalNumberDirty) {
        return addError(tempErrorList, "personalNumber", translation.pages?.order.form.personalNumberNumberError);
      }
    }

    if (!isValidCPR(personalNumber) && personalNumber.length === 10) {
      if (personalNumberDirty) {
        return addError(tempErrorList, "personalNumber", translation.pages?.order.form.personalNumberValidError);
      }
    }
  }

  const validateEmail = (email: string) => {
    let tempErrorList = {};
    if (email.length === 0) {
      if (emailDirty) {
        return addError(tempErrorList, "email", translation.pages?.order.form.emailLengthError);
      }
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && email.length !== 0) {
      if (emailDirty) {
        return addError(tempErrorList, "email", translation.pages?.order.form.emailValidError);
      }
    }
  }

  const validatePhoneNumber = (phoneNumber: string) => {
    let tempErrorList = {};
    if (phoneNumber.length === 0) {
      if (phoneNumberDirty) {
        return addError(tempErrorList, "phoneNumber", translation.pages?.order.form.phoneNumberEmptyError);
      }
    }

    // Check if the phone number starts with "+" followed by the phoneCountryCode
    const countryCodeRegex = new RegExp(`^\\+${phoneCountryCode}`);
    if (!countryCodeRegex.test(phoneNumber) && phoneNumber.length !== 0) {
      if (!phoneNumberDirty) {
        setPhoneNumberDirty(true);
      } else {
        return addError(tempErrorList, "phoneNumber", `${translation.pages?.order.form.phoneNumberCountryCodeError} (+${phoneCountryCode})`);
      }
    }

    // Check if the phone number (excluding the "+") is only digits and between 5 and 15 numbers long
    const phoneNumberWithoutCountryCode = phoneNumber.replace(`+${phoneCountryCode}`, '');
    if (!/^\d{5,15}$/.test(phoneNumberWithoutCountryCode) && phoneNumber.length !== 0) {
      if (!phoneNumberDirty) {
        setPhoneNumberDirty(true);
      } else {
        return addError(tempErrorList, "phoneNumber", translation.pages?.order.form.phoneNumberNumberError);
      }
    }

    if (phoneNumber.length !== 0 && phoneNumber.length < 5) {
      if (phoneNumberDirty) {
        return addError(tempErrorList, "phoneNumber", translation.pages?.order.form.phoneNumberTooShortError);
      }
    }

    if (phoneNumber.length > 15) {
      if (phoneNumberDirty) {
        return addError(tempErrorList, "phoneNumber", translation.pages?.order.form.phoneNumberTooLongError);
      }
    }
  }

  const validateCompanyOrgNumber = (companyOrgNumber: string) => {
    let tempErrorList = {};
    if (companyOrgNumber.length === 0) {
      if (companyOrgNumberDirty) {
        return addError(tempErrorList, "companyOrgNumber", translation.pages?.order.form.companyOrgNumberError);
      }
    }

    if (companyOrgNumber.length !== 10) {
      if (companyOrgNumberDirty) {
        return addError(tempErrorList, "companyOrgNumber", translation.pages?.order.form.companyOrgNumberLengthError);
      }
    }

    if (companyOrgNumber) {
      if (!/^\d+$/.test(companyOrgNumber) && companyOrgNumber.length === 10) {
        if (companyOrgNumberDirty) {
          return addError(tempErrorList, "companyOrgNumber", translation.pages?.order.form.companyOrgNumberNumberError);
        }
      }
    }
  }

  const validateForm = () => {
    const isCompany = activeButton === "company";
    let tempErrorList = {};

    const personalNumberErrors = validatePersonalNumber(user?.personalNumber ?? "");
    tempErrorList = { ...tempErrorList, ...personalNumberErrors };

    const emailErrors = validateEmail(user?.email ?? "");
    tempErrorList = { ...tempErrorList, ...emailErrors };


    if (!user?.streetAddress || user?.streetAddress?.length === 0) {
      if (streetAddressDirty) {
        tempErrorList = addError(tempErrorList, "streetAddress", translation.pages?.order.form.streetAddressError);
      }
    }

    if (!user?.postalCode || user?.postalCode?.length === 0) {
      if (postalCodeDirty) {
        tempErrorList = addError(tempErrorList, "postalCode", translation.pages?.order.form.postalCodeError);
      }
    }

    if (!user?.city || user?.city?.length === 0) {
      if (cityDirty) {
        tempErrorList = addError(tempErrorList, "city", translation.pages?.order.form.cityError);
      }
    }

    const phoneNumberErrors = validatePhoneNumber(user?.phoneNumber ?? "");
    tempErrorList = { ...tempErrorList, ...phoneNumberErrors };

    if (isCompany) {

      const companyOrgNumberErrors = validateCompanyOrgNumber(user.companyOrgNumber ?? "");
      tempErrorList = { ...tempErrorList, ...companyOrgNumberErrors };

      if (user.companyName === undefined || !user.companyName || (user.companyName && user.companyName.length === 0)) {
        if (companyNameDirty) {
          tempErrorList = addError(tempErrorList, "companyName", translation.pages?.order.form.companyNameError);
        }
      }

      if (user.isInvoice && (user.invoiceEmail.length === 0)) {
        if (invoiceEmailDirty) {
          tempErrorList = addError(tempErrorList, "invoiceEmail", translation.pages?.order.form.invoiceEmailError);
        }
      }
    }

    setErrorList(tempErrorList);
  };

  useEffect(() => {
    if (errorList && Object.keys(errorList).length > 0 && Object.keys(errorList).find((key) => typeof errorList[key] !== undefined)) {
      setIsValid(false);
    } else if (user.city && user.streetAddress && user.postalCode && user.email && user.phoneNumber && user.personalNumber) {
      if (user.isCompany && user.companyName && user.companyOrgNumber && !user.isInvoice) {
        setIsValid(true);
      }
      else if (user.isCompany && user.companyName && user.companyOrgNumber && user.isInvoice && user.invoiceEmail) {
        setIsValid(true);
      }
      else if (!user.isCompany) {
        setIsValid(true);
      }
      else {
        setIsValid(false);
      }
    }
    else {
      setIsValid(false);
    }
  }, [errorList]);

  useEffect(() => {
    setValidationValue?.("formValidated", isValid);
  }, [isValid]);

  const handleAllFieldsValidation = (): void => {
    validateForm();
  };

  const Userdatareq = async () => {
    const userresponse = await fetch("/api/user/userdata", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Country: country,
      },
    });

    if (!userresponse.ok) {
      throw new Error("Network response was not ok");
    }
    userdata = (await userresponse.json()) as Userprops[];

    if (userdata?.length > 0) {
      if (isloggedin) {
        setPersonalNumberDirty(true);
        setCompanyNameDirty(true);
        setCompanyOrgNumberDirty(true);
        setInvoiceEmailDirty(true);
        setStreetAddressDirty(true);
        setPostalCodeDirty(true);
        setCityDirty(true);
        setEmailDirty(true);
        setPhoneNumberDirty(true);
      }
      const persondata = userdata.find((element) => element.company === "0");
      const orgdata = userdata.find((element) => element.company === "1");


      if (setallValues && persondata && orgdata && userdata?.length < 3) {
        if (orgdata?.orgNumber && orgdata.orgNumber.length > 0) {
          setDisableCompanyNumber(true);
        }
        setActiveButton("person");
        updateValues(
          false,
          user.isInvoice,
          user.forename,
          user.surname,
          persondata.invoiceEmail,
          persondata.invoiceMarkup,
          persondata.pnr,
          persondata.mainAddress1,
          persondata.mainPostCode,
          persondata.mainTown,
          persondata.email,
          persondata.phone,
          persondata.customerID,
          orgdata.companyName,
          orgdata.orgNumber
        );
      }

      if (setallValues && persondata && (!orgdata || userdata?.length > 2)) {
        setActiveButton("person");
        updateValues(
          false,
          user.isInvoice,
          user.forename,
          user.surname,
          persondata.invoiceEmail,
          persondata.invoiceMarkup,
          persondata.pnr,
          persondata.mainAddress1,
          persondata.mainPostCode,
          persondata.mainTown,
          persondata.email,
          persondata.phone,
          persondata.customerID,
          "",
          ""
        );
      }

      if (setallValues && !persondata && orgdata && userdata?.length < 2) {
        if (orgdata?.orgNumber && orgdata.orgNumber.length > 0) {
          setDisableCompanyNumber(true);
        }
        setActiveButton("company");
        updateValues(
          true,
          user.isInvoice,
          user.forename,
          user.surname,
          orgdata.invoiceEmail,
          orgdata.invoiceMarkup,
          orgdata.pnr,
          orgdata.mainAddress1,
          orgdata.mainPostCode,
          orgdata.mainTown,
          orgdata.email,
          orgdata.phone,
          orgdata.customerID,
          orgdata.companyName,
          orgdata.orgNumber
        );
      }
    }
  };

  if (isloggedin && userdata === null && user.personalNumber.length === 0) {
    Userdatareq();
  }

  useEffect(() => {
    handleAllFieldsValidation();
  }, [user]);

  return (
    <>
      <div className="w-full pt-2">
        <button
          className={`w-6/12 textSmall font-bold p-4 h-14 !rounded-r-lg ${activeButton === "person"
            ? "btn"
            : "btn dark-contrast !border-gs-grey"
            }`}
          onClick={() => {
            setActiveButton("person");
            setValue?.("isCompany", false);
          }}
        >
          {translation.pages?.order.form.person ?? "Person"}
        </button>
        <button
          className={`w-6/12 textSmall font-bold p-4 h-14 !rounded-l-lg ${activeButton === "company"
            ? "btn"
            : "btn dark-contrast !border-gs-grey"
            }`}
          onClick={() => {
            setActiveButton("company");
            setValue?.("isCompany", true);
          }}
        >
          {translation.pages?.order.form.company ?? "Company"}
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <FormInput
          value={user.personalNumber}
          disabled={isloggedin}
          hideLabel={true}
          label={translation.pages?.order.form.personalNumber}
          name="personalNumber"
          isMandatory={true}
          onValueChange={(name, value) => {
            setValue?.(
              "personalNumber",
              value
            );
            setPersonalNumberDirty(true);
            handleAllFieldsValidation();
          }}
          errors={errorList}
          className="lg:col-span-2"
        />

        {activeButton === "company" && (
          <>
            <FormInput
              value={user.companyName}
              hideLabel={true}
              disabled={disableCompanyNumber}
              label={translation.pages?.order.form.companyName}
              name="companyName"
              isMandatory={true}
              onValueChange={(name, value) => {
                setValue?.(
                  "companyName",
                  value
                );
                setCompanyNameDirty(true);
                handleAllFieldsValidation();
              }}
              errors={errorList}
            />

            <FormInput
              value={user.companyOrgNumber}
              hideLabel={true}
              disabled={disableCompanyNumber}
              label={translation.pages?.order.form.organisationsNumber}
              name="companyOrgNumber"
              isMandatory={true}
              onValueChange={(name, value) => {
                setValue?.(
                  "companyOrgNumber",
                  value
                );
                setCompanyOrgNumberDirty(true);
                handleAllFieldsValidation();
              }}
              errors={errorList}
            />
          </>
        )}

        <>
          <FormInput
            value={user.streetAddress}
            hideLabel={true}
            label={translation.general.streetAddress}
            name="streetAddress"
            isMandatory={true}
            placeholder={translation.general.streetAddress}
            onValueChange={(name, value) => {
              setValue?.(
                "streetAddress",
                value
              );
              setStreetAddressDirty(true);
              handleAllFieldsValidation();
            }}
            errors={errorList}
            className="lg:col-span-2"
          />

          <FormInput
            value={user.postalCode}
            hideLabel={true}
            label={translation.pages?.order.form.postalCode}
            name="postalCode"
            isMandatory={true}
            placeholder={translation.pages?.order.form.postalCode}
            onValueChange={(name, value) => {
              setValue?.(
                "postalCode",
                value
              );
              setPostalCodeDirty(true);
              handleAllFieldsValidation();
            }}
            errors={errorList}
          />

          <FormInput
            value={user.city}
            hideLabel={true}
            label={translation.pages?.order.form.city}
            name="city"
            isMandatory={true}
            placeholder={translation.pages?.order.form.city}
            onValueChange={(name, value) => {
              setValue?.(
                "city",
                value
              );
              setCityDirty(true);
              handleAllFieldsValidation();
            }}
            errors={errorList}
          />
        </>

        <FormInput
          value={user.email}
          hideLabel={true}
          label={translation.pages?.order.form.email}
          name="email"
          isMandatory={true}
          placeholder={translation.pages?.order.form.email}
          onValueChange={(name, value) => {
            setValue?.(
              "email",
              value
            );
            setEmailDirty(true);
            handleAllFieldsValidation();
          }}
          errors={errorList}
          type="email"
        />

        <FormInput
          value={user.phoneNumber}
          hideLabel={true}
          label={translation.pages?.order.form.phoneNumber}
          name="phoneNumber"
          isMandatory={true}
          placeholder={translation.pages?.order.form.phoneNumber}
          onValueChange={(name, value, countryData) => {
            setPhoneCountryCode(countryData?.dialCode);
            setValue?.(
              "phoneNumber",
              value
            );
            setPhoneNumberDirty(true);
            handleAllFieldsValidation();
          }}
          onMount={(name, value, countryData) => {
            setPhoneCountryCode(countryData?.dialCode);
          }}
          errors={errorList}
          type="tel"
          country={country}
        />
      </div>
      {activeButton === "company" && (
        <div className="w-full pt-2 inline-flex space-x-4">
          <>
            <FormCheckbox
              name="isInvoice-checkbox"
              checked={selectedCheckbox === true}
              label={
                translation.pages?.order.form.chooseInvoice ?? "Välja faktura"
              }
              onChange={() => {
                setSelectedCheckbox(!selectedCheckbox);
                setValue?.("isInvoice", !selectedCheckbox);
                setValue?.("invoiceEmail", "");
                setValue?.("invoiceReference", "");
              }}
              errors={errorList}
            ></FormCheckbox>
          </>
        </div>
      )}
      {activeButton === "company" && selectedCheckbox && (
        <div className="w-full pt-2 inline-flex space-x-4">
          <FormInput
            //className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight"
            value={user.invoiceEmail}
            hideLabel={true}
            label={translation.pages?.order.form.invoiceEmail}
            name="invoiceEmail"
            isMandatory={false}
            onChange={(e) => {
              setValue?.(
                "invoiceEmail",
                typeof e === "string" ? e : e.target.value
              );
              setInvoiceEmailDirty(true);
              handleAllFieldsValidation();
            }}
            errors={errorList}
            placeholder={
              translation.pages?.order.form.invoiceEmail ?? "Faktura mail"
            }
          />
          <FormInput
            value={user.invoiceReference}
            hideLabel={true}
            label={translation.pages?.order.form.invoiceReference}
            name="invoiceReference"
            isMandatory={false}
            //className="appearance-none border rounded py-2 px-3 text-gray-700 leading-tight"
            onChange={(e) => {
              setValue?.(
                "invoiceReference",
                typeof e === "string" ? e : e.target.value
              );
              handleAllFieldsValidation();
            }}
            errors={errorList}
            placeholder={
              translation.pages?.order.form.invoiceRef ?? "Faktura reference"
            }
          />
        </div>
      )}
    </>
  );
}
