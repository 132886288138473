'use client'
import React, { useEffect, useRef } from 'react';
import styles from './embeddedvideo.module.scss';

interface EmbeddedVideoProps {
    videoUrl: string;
    className?: string;
}

const EmbeddedVideo: React.FC<EmbeddedVideoProps> = ({ videoUrl, className = "" }) => {
    const aspectRatio = (348 / 619) * 100; // Aspect ratio for 619x348
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const adjustIframeSize = () => {
            if (containerRef.current && iframeRef.current) {
                const containerWidth = containerRef.current.clientWidth;
                const containerHeight = containerRef.current.clientHeight;
                const aspectRatio = 619 / 348;

                if (containerWidth / containerHeight > aspectRatio) {
                    iframeRef.current.style.width = `${containerHeight * aspectRatio}px`;
                    iframeRef.current.style.height = `${containerHeight}px`;
                } else {
                    iframeRef.current.style.width = `${containerWidth}px`;
                    iframeRef.current.style.height = `${containerWidth / aspectRatio}px`;
                }
            }
        };

        adjustIframeSize();
        window.addEventListener('resize', adjustIframeSize);

        return () => {
            window.removeEventListener('resize', adjustIframeSize);
        };
    }, []);

    return (
        <div ref={containerRef} className={styles.videoContainer} >
            <iframe
                ref={iframeRef}
                className={`aspect-video w-full ${className}`}
                allowFullScreen
                allow="autoplay; fullscreen"
                src={videoUrl}
                scrolling="no"
                
            ></iframe>
        </div>
    );
};

export default EmbeddedVideo;
