'use client';
import { iDictionary } from '@/app/models/dictionary';
import React, { useEffect, useState } from 'react';

interface FormRadiobuttonProps {
    name: string;
    groupName: string;
    value?: string;
    label?: string;
    errors?: iDictionary;
    isRequiredText?: string;
    className?: string;
    isMandatory?: boolean;
    helperText?: string;
    disabled?: boolean;
    children?: React.ReactNode;
    isWhite?: boolean;
    checked?: boolean;
    isReversed?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onError?: (name: string, error: string | string[]) => void;
}

const FormRadiobutton: React.FC<FormRadiobuttonProps> = ({
    name,
    groupName,
    value,
    label,
    errors,
    isRequiredText,
    className = '',
    isMandatory = false,
    helperText = null,
    disabled = false,
    children,
    isWhite = false,
    onChange,
    checked = undefined,
    isReversed = false,
    onError
}) => {
    const radiobuttonClass = isWhite ? 'radiobutton-white' : 'radiobutton-default';
    const [showError, setShowError] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);

    useEffect(() => {
        if (errors && errors[name]) {
            setShowError(true);

            if (typeof errors[name] === 'string') {
                setErrorMessages([errors[name]]);
            } else {
                setErrorMessages(Object.values(errors[name]));
            }

            if (onError) {
                onError(name, errors[name]);
            }

        } else {
            setShowError(false);
        }
    }, [errors]);
    const inputProps = {
        id: name,
        name: groupName,
        'aria-label': label,
        disabled,
        onChange,
        type: "radio",
        value ,
        checked,
    };

    return (
        <div className={`${className} ${radiobuttonClass} w-full ${showError ? "validation-error" : ""}`}>
            {isReversed ? (
                <>
                    <label htmlFor={name}>
                        <div className="radiobutton-wrapper flex items-start">
                            <div className="radiobutton-content pl-4 w-full">
                                {label ? (
                                    <span>{`${label}${isMandatory ? '*' : ''}`}</span>
                                ) : (
                                    children
                                )}
                            </div>
                            <input
                                {...inputProps}
                            />
                            <span aria-hidden="true" className="custom-radiobutton"></span>
                        </div>
                    </label>
                    {helperText && <span className="helper-text">{helperText}</span>}
                    {errors && errors[name] && (
                        <span role="alert">{isRequiredText}</span>
                    )}
                </>

            ) : (
                <>
                    <label htmlFor={name}>
                        <div className="radiobutton-wrapper flex items-start">
                            <input
                                {...inputProps}
                            />
                            <span aria-hidden="true" className="custom-radiobutton"></span>
                            <div className="radiobutton-content pl-4 w-full">
                                {label ? (
                                    <span>{`${label}${isMandatory ? '*' : ''}`}</span>
                                ) : (
                                    children
                                )}
                            </div>
                        </div>
                    </label>
                    {helperText && <span className="helper-text">{helperText}</span>}
                    {errors && errors[name] && (
                        <span role="alert">{isRequiredText}</span>
                    )}
                </>
            )}

        </div>
    );
};

export default FormRadiobutton;