'use client';

import React, { useEffect, useState } from 'react';
import styles from './mypagescomponentcontainer.module.scss';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import { iMyPagesUser } from '@/app/models/myPages/myPagesUser';
import StorageUnitsList from '../MyPages/StorageUnitsList';
import InvoicesList from '../MyPages/InvoicesList';
import Profile from '../MyPages/Profile';
import Modal from '../Modal/Modal';
import Loader from '../Loader/Loader';
import CardInformation from '../MyPages/CardInformation';
import { clientLogInfo } from '@/app/helpers/clientLoggingHelper';

interface MyPagesComponentContainerProps {
    requestContext: iRequestContext;
    selectedProfile: iMyPagesUser;
    onUpdateProfile: (updatedProfile: iMyPagesUser) => void;
}


export default function MyPagesComponentContainer({ requestContext, selectedProfile, onUpdateProfile }: MyPagesComponentContainerProps) {
    const getCustomerId = (profile: iMyPagesUser) => {
        return profile.userDataPerson ? profile.userDataPerson.customerId : profile.userDataOrganisation?.customerId;
    }

    clientLogInfo("MyPagesComponentContainer.tsx", "loading component", "selectedProfile", selectedProfile);

    const canChangeUnit = (profile: iMyPagesUser) => {
        let canChangeUnit = true;

        if (profile.userDataPerson) {
            canChangeUnit = profile.userDataPerson.canChangeUnit;
        } else if (profile.userDataOrganisation) {
            canChangeUnit = profile.userDataOrganisation.canChangeUnit;
        }

        return canChangeUnit;
    }

    const canPayByInvoice = (profile: iMyPagesUser) =>
    {
        let canPayByInvoice = false;

        if (profile.userDataPerson) {
            canPayByInvoice = profile.userDataPerson.payByInvoice;
        } else if (profile.userDataOrganisation) {
            canPayByInvoice = profile.userDataOrganisation.payByInvoice;
        }

        return canPayByInvoice;
    }

    return (
        <>
            {selectedProfile.storageData && selectedProfile.storageData.length > 0 && (
                <StorageUnitsList
                    storageUnits={selectedProfile.storageData}
                    requestContext={requestContext}
                    customerId={getCustomerId(selectedProfile) ?? ''}
                    canChangeUnit={canChangeUnit(selectedProfile)}
                />
            )}

            {selectedProfile.invoiceData && selectedProfile.invoiceData.length > 0 && (
                <InvoicesList invoices={selectedProfile.invoiceData} requestContext={requestContext} customerId={getCustomerId(selectedProfile) ?? ''} />
            )}

            {(selectedProfile.userDataPerson || selectedProfile.userDataOrganisation) && (
                <Profile profile={selectedProfile} payByInvoice={canPayByInvoice(selectedProfile)} requestContext={requestContext} country={requestContext.country} onUpdateProfile={onUpdateProfile} />
            )}

            <CardInformation
                profile={selectedProfile}
                country={requestContext.country}
                changePaymentUrl={requestContext.configurations.pages.changePayment.url}
                translations={requestContext.translations}
                payByInvoice={canPayByInvoice(selectedProfile)}
            />
        </>
    );
};
