"use client";
import React, { useEffect, useState } from "react";
import styles from "./changestorage.module.scss";
import SelectStorageSizes from "../SelectStorageSizes/SelectStorageSizes";
import { iStorageSize } from "@/app/models/storageSize";
import Loader from "../Loader/Loader";
import { MapUnitFeatureAndUnit } from "@/app/models/apiModels/mapUnitFeatureAndUnit";
import {
  clientLogError,
  clientLogInfo,
} from "@/app/helpers/clientLoggingHelper";
import FormRadiobutton from "../Forms/FormRadiobutton";
import { iSize } from "@/app/models/apiModels/size";
import { iGroupSize } from "@/app/models/apiModels/Sizes/groupSize";
import FormCheckbox from "../Forms/FormCheckbox";
import { iMyPagesUnitData } from "@/app/models/myPages/myPagesUnitData";
import { iFacilitySimpelModel } from "@/app/models/apiModels/facilitySimpel";
import { iGetUnitRequestModel } from "@/app/models/apiModels/requestModels/getUnitRequestModel";
import { iUnitObject } from "@/app/models/unitObject";
import FormSelect from "../Forms/FormSelect";
import { iSelectItem } from "@/app/models/componentModels/selectItem";
import {
  getBookableDateOptions,
  TwoDigitFormattedDate,
} from "@/app/helpers/dateHelper";
import { formatCurrency } from "@/app/helpers/numberHelper";
import StorageSizeCardImage from "../StorageSizeCardImage/StorageSizeCardImage";
import LinkElement from "../General/LinkElement";
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";
import { eChevronPosition } from "@/app/models/enum/chevronPosition";
import { iSizeConfigurations } from "@/app/models/configurations";
import { eLinkSize } from "@/app/models/enum/linkSize";
import { eTarget } from "@/app/models/enum/target";
import { iLinkContent } from "@/app/models/iLinkContent";
import { iChangeSizeRequestModel } from "@/app/models/apiModels/requestModels/changeSizeRequestModel";
import Modal from "../Modal/Modal";
import { useRouter } from "next/navigation";
import { iGroupSizeObject } from "@/app/models/groupSizeObject";

export interface Props {
  translations: any;
  currentUnit: iMyPagesUnitData;
  facility: iFacilitySimpelModel;
  currentSizeId: number;
  storageSizes: iStorageSize[];
  availableSizes: iSize[];
  country: string;
  language: string;
  bookingDaysAhead: number;
  sizeConfigurations: iSizeConfigurations;
  integrityPolicyLink: iLinkContent;
  insuranceTermsLink: iLinkContent;
  isCompany: boolean;
  customerId: string;
  culture: string;
  myPagesUrl: string;
}

export default function ChangeStorage({
  translations,
  currentUnit,
  facility,
  currentSizeId,
  storageSizes,
  availableSizes,
  country,
  language,
  bookingDaysAhead,
  sizeConfigurations,
  integrityPolicyLink,
  insuranceTermsLink,
  isCompany,
  customerId,
  culture,
  myPagesUrl,
}: Props) {
  const router = useRouter();
  const [selectedSizeCodeId, setSelectedSizeCodeId] = useState<string>();
  const [selectedExactSizeSizeCodeId, setSelectedExactSizeSizeCodeId] =
    useState<string | undefined>();
  const [availableGroupSizes, setAvailableGroupSizes] =
    useState<iGroupSize[]>();
  const [selectedFeature, setSelectedFeature] = useState<string>("");
  const [selectedFloor, setSelectedFloor] = useState<string[]>([]);
  const [filteredUnits, setFilteredUnits] = useState<MapUnitFeatureAndUnit[]>(
    []
  );
  const [mapUnitFeatureAndUnitResponse, setMapUnitFeatureAndUnitResponse] =
    useState<MapUnitFeatureAndUnit[]>();
  const [selectedUnitNumber, setSelectedUnitNumber] = useState<string>();
  const [selectedUnitId, setSelectedUnitId] = useState<string>();
  const [selectedUnit, setSelectedUnit] = useState<iUnitObject>();
  const [dateOptions, setDateOptions] = useState<
    { selectItem: iSelectItem; date: Date }[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [integrationPolicyChecked, setIntegrationPolicyChecked] =
    useState<boolean>(false);
  const [generalPolicyChecked, setGeneralPolicyChecked] =
    useState<boolean>(false);
  const [approvalPolicyChecked, setApprovalPolicyChecked] =
    useState<boolean>(false);
  const [companyPolicyChecked, setCompanyPolicyChecked] =
    useState<boolean>(false);

  const [isLoadingMain, setIsLoadingMain] = useState<boolean>(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(false);
  const [isLoadingUnits, setIsLoadingUnits] = useState<boolean>(false);
  const [isLoadingDate, setIsLoadingDate] = useState<boolean>(false);
  const [isLoadingSend, setIsLoadingSend] = useState<boolean>(false);

  const [errorDetected, setErrorDetected] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const date = new Date().toISOString().split("T")[0];
  const todaysDate = TwoDigitFormattedDate(date, culture);

  useEffect(() => {
    const currentStorageSize = storageSizes.find(
      (size) => size.id === currentSizeId
    );

    if (currentStorageSize) {
      setSelectedSizeCodeId(currentStorageSize.sizeCodeId);
    }
  }, []);

  useEffect(() => {
    setIsLoadingMain(true);
    if (selectedSizeCodeId) {
      const filteredSize = availableSizes.find(
        (size) => size.sizeCodeId === selectedSizeCodeId
      );

      if (filteredSize && filteredSize.groupSizes) {
        setAvailableGroupSizes(filteredSize.groupSizes);
      } else {
        setAvailableGroupSizes([]);
      }

      setSelectedExactSizeSizeCodeId(undefined);
    }
  }, [selectedSizeCodeId]);

  useEffect(() => {
    setIsLoadingMain(false);
  }, [availableGroupSizes]);

  useEffect(() => {
    setIsLoadingFilters(true);

    const fetchGetMapUnitFeatureAndUnit = async () => {
      try {
        const response = await fetch("/api/order/getMapUnitFeatureAndUnit", {
          method: "POST",
          headers: {
            Country: country,
          },
          body: JSON.stringify({
            siteId: facility.id,
            unitSizeCode: selectedExactSizeSizeCodeId,
            onlyCurrentDate: false,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const mapUnitFeatureAndUnitResponse =
          (await response.json()) as MapUnitFeatureAndUnit[];

        setMapUnitFeatureAndUnitResponse(mapUnitFeatureAndUnitResponse);

        setSelectedFeature("warm");
        setSelectedFloor([]);
        setSelectedDate(undefined);
        setSelectedUnit(undefined);
      } catch (error) {
        clientLogError(
          "ChangeStorage.tsx",
          "fetchGetMapUnitFeatureAndUnit",
          `Data: facilityId/siteId: ${facility.id}, selectedExactSizeSizeCodeId: ${selectedExactSizeSizeCodeId}, onlyCurrentDate: false`,
          "Error:",
          error
        );
      } finally {
        setIsLoadingFilters(false);
      }
    };
    fetchGetMapUnitFeatureAndUnit();
  }, [selectedExactSizeSizeCodeId]);

  useEffect(() => {
    setIsLoadingUnits(true);
    const filteredUnitsTemp = mapUnitFeatureAndUnitResponse?.filter(
      (mappedUnit) => {
        const isGroundFloorMatch =
          (selectedFloor.includes("ground") &&
            mappedUnit.feature.iS_GROUND_FLOOR === "1") ||
          !selectedFloor.includes("ground") ||
          selectedFloor.length === 0;
        const isElevatorFloorMatch =
          (selectedFloor.includes("elevatorFloor") &&
            mappedUnit.feature.iS_GROUND_FLOOR === "0") ||
          !selectedFloor.includes("elevatorFloor") ||
          selectedFloor.length === 0;
        const isDirectAccessMatch =
          (selectedFloor.includes("directAccess") &&
            mappedUnit.feature.iS_DIRECT_ACCESS === "1") ||
          !selectedFloor.includes("directAccess") ||
          selectedFloor.length === 0;
        const isWarmMatch =
          (selectedFeature === "warm"
            ? mappedUnit.feature.iS_COLD === "0"
            : mappedUnit.feature.iS_COLD === "1") || selectedFeature === "";
        return (
          isGroundFloorMatch &&
          isElevatorFloorMatch &&
          isDirectAccessMatch &&
          isWarmMatch
        );
      }
    );
    setFilteredUnits(filteredUnitsTemp ?? []);
  }, [selectedFloor, selectedFeature]);

  useEffect(() => {
    setIsLoadingUnits(false);
  }, [filteredUnits]);

  useEffect(() => {
    const getUnit = async () => {
      if (!selectedUnitId) {
        return;
      }

      try {
        const requestModel: iGetUnitRequestModel = {
          country: country,
          unitId: selectedUnitId,
        };
        const response = await fetch("/api/unit/getUnitById", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestModel),
        });

        if (!response.ok) {
          const responseBody = await response.json();
          clientLogError(
            "ChangeStorage.tsx",
            "getUnit",
            `Data: unitId: ${selectedUnitId}, country: ${country}`,
            "Error:",
            responseBody
          );
          throw new Error("Network response was not ok");
        }
        const unit = (await response.json()) as iUnitObject;
        clientLogInfo(
          "ChangeStorage.tsx",
          "getUnit",
          `Data: unitId: ${selectedUnitId}, country: ${country}`,
          "Unit:",
          unit
        );
        setSelectedUnit(unit);

        const dateOptionsTemp: { selectItem: iSelectItem; date: Date }[] =
          getBookableDateOptions(
            new Date(),
            bookingDaysAhead,
            unit.BookingFrom,
            unit.BookingTo,
            language
          );

        setDateOptions(dateOptionsTemp);
      } catch (error) {
        clientLogError(
          "ChangeStorage.tsx",
          "getUnit",
          `Data: unitId: ${selectedUnitId}, country: ${country}`,
          "Error:",
          error
        );
      } finally {
        setIsLoadingDate(false);
      }
    };
    getUnit();
  }, [selectedUnitId]);

  useEffect(() => {
    setIsLoadingDate(false);
  }, [dateOptions]);

  const initError = (title: string = "", message: string = "") => {
    if (title === "") {
      title = translations.general.errors.e500.title;
    }

    if (message === "") {
      message = translations.general.errors.e500.message;
    }

    setErrorTitle(title);
    setErrorMessage(message);
    setErrorDetected(true);
    setIsLoadingSend(false);
  };

  const clearError = () => {
    //TODO: Fix this later
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.width = ""; // Reset width
    window.scrollTo(0, parseInt(scrollY || "0") * -1);

    setErrorDetected(false);
    setErrorTitle("");
    setErrorMessage("");
  };

  const handleChangeSizeGroup = (sizeCodeId: string, sizeId: number) => {
    clientLogInfo(
      "ChangeStorage.tsx",
      "handleChangeSizeGroup",
      `Data: sizeCodeId: ${sizeCodeId}, sizeId: ${sizeId}`,
      "Change size group"
    );
    setSelectedSizeCodeId(sizeCodeId);
  };

  const handleGroupRadioChange = (sizeCodeId: string) => {
    clientLogInfo(
      "ChangeStorage.tsx",
      "handleGroupRadioChange",
      `Data: sizeCodeId: ${sizeCodeId}`,
      "Change group radio"
    );
    setSelectedExactSizeSizeCodeId(sizeCodeId);
  };

  const handleUnitCheckboxChange = (mappedUnit: MapUnitFeatureAndUnit) => {
    clientLogInfo(
      "ChangeStorage.tsx",
      "handleUnitCheckboxChange",
      "Data: mappedUnit:",
      mappedUnit
    );
    setIsLoadingDate(true);
    setSelectedUnitId(mappedUnit.feature.unitID);
    setSelectedUnitNumber(mappedUnit.unitNumber);
    setSelectedDate(undefined);
  };

  const handleDateChange = (selected: iSelectItem) => {
    clientLogInfo(
      "ChangeStorage.tsx",
      "handleDateChange",
      "selected:",
      selected
    );
    const selectedDate = dateOptions.find(
      (dateOption) => dateOption.selectItem.value === selected.value
    );
    if (selectedDate) {
      setSelectedDate(selectedDate.date);
      clientLogInfo(
        "ChangeStorage.tsx",
        "handleDateChange",
        "selectedDate",
        selectedDate.date
      );
    }
  };

  const handleSubmit = async () => {
    setIsLoadingSend(true);
    if (
      !integrationPolicyChecked ||
      !generalPolicyChecked ||
      !approvalPolicyChecked ||
      (isCompany && !companyPolicyChecked)
    ) {
      //TODO: show error message, need top accept all policies
      clientLogError(
        "ChangeStorage.tsx",
        "handleSubmit",
        "Need to accept all policies",
        `Data: integrationPolicyChecked: ${integrationPolicyChecked}, generalPolicyChecked: ${generalPolicyChecked}, approvalPolicyChecked: ${approvalPolicyChecked}, isCompany: ${isCompany} and companyPolicyChecked: ${companyPolicyChecked}`
      );
      setIsLoadingSend(false);
      return;
    }

    if (!selectedUnit || !selectedUnitId || !selectedDate) {
      //TODO: Show error message, need to select unit and date
      clientLogError(
        "ChangeStorage.tsx",
        "handleSubmit",
        "Need to select unit and date",
        `Data: selectedUnit: ${JSON.stringify(
          selectedUnit
        )}, selectedUnitId: ${selectedUnitId}, selectedDate: ${selectedDate}`
      );
      setIsLoadingSend(false);
      return;
    }

    clientLogInfo(
      "ChangeStorage.tsx",
      "handleSubmit",
      `Data: country: ${country}, customerId: ${customerId}, selectedUnitId: ${selectedUnitId}, currentUnit.unitId: ${currentUnit.unitId}, selectedDate: ${selectedDate}, currentUnit.unitSubContractId: ${currentUnit.unitSubContractId}`
    );

    try {
      const requestBody: iChangeSizeRequestModel = {
        changeDate: selectedDate.toISOString(),
        customerId: customerId,
        newUnitId: selectedUnitId,
        currentUnitId: currentUnit.unitId,
        currentUnitSubContractId: currentUnit.unitSubContractId,
      };

      const response = await fetch("/api/unit/changeSize", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Country: country,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        initError(
          translations.pages.changeStorage.errorTitle,
          translations.pages.changeStorage.errorMessage
        );
      }

      const data = await response.json();
      if (data.isError) {
        initError(
          translations.pages.changeStorage.errorTitle,
          translations.pages.changeStorage.errorMessage
        );
      }
      clientLogInfo(
        "ChangeStorage.tsx",
        "handleSubmit",
        "Change size",
        `Data: country: ${country}, customerId: ${customerId}, selectedUnitId: ${selectedUnitId}, currentUnit.unitId: ${currentUnit.unitId}, selectedDate: ${selectedDate}, currentUnit.unitSubContractId: ${currentUnit.unitSubContractId}`,
        "Response Data",
        data
      );

      if (!data.isError) {
        const todaysDate = new Date().toISOString().split("T")[0];

        try {
          const response = await fetch("/api/user/updateLastChangedUnit", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Country: country,
            },
            body: JSON.stringify({
              uniqueIdentifier: customerId,
              newDate: todaysDate,
            }),
          });

          const data = await response.json();
          clientLogInfo(
            "ChangeStorage.tsx",
            "handleSubmit",
            "Update Last Changed Unit",
            `Data: uniqueIdentifier: ${country}, newDate: ${customerId}`,
            "Response Data",
            data
          );

          router.push(myPagesUrl);
        } catch (error) {
          clientLogError(
            "ChangeStorage.tsx",
            "handleSubmit",
            "Error updating last changed unit",
            error
          );
        }
      }
    } catch (error) {
      clientLogError(
        "ChangeStorage.tsx",
        "handleSubmit",
        "Error changing size",
        `Data: country: ${country}, customerId: ${customerId}, selectedUnitId: ${selectedUnitId}, currentUnit.unitId: ${currentUnit.unitId}, selectedDate: ${selectedDate}, currentUnit.unitSubContractId: ${currentUnit.unitSubContractId}`,
        error
      );
    } finally {
      setIsLoadingSend(false);
    }
  };

  const unitTranslations = translations.pages?.order?.unitInformation;

  return (
    <div>
      {errorDetected ? (
        <Modal
          isOpen={errorDetected}
          onClose={clearError}
          closeTranslation={translations.general.closePopup}
        >
          <div>
            <h2>{errorTitle}</h2>
            <div className="mb-8">{errorMessage}</div>
            <LinkElement
              title={translations.general.close}
              isButton={true}
              onClick={clearError}
              buttonColorTheme={eButtonColorTheme.DarkContrast}
              className="w-full mt-6"
            >
              {translations.general.close}
            </LinkElement>
          </div>
        </Modal>
      ) : (
        <>
          <div className="mb-6">
            <h2>{translations.general.information}</h2>
            <div>
              <span className="font-bold">
                {translations.pages.changeStorage.unitChangeInformation}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div className="bg-white rounded-xl p-6 lg:p-10">
              {/* YOUR STORAGE */}
              <h2>{translations.pages.changeStorage.yourStorage}</h2>
              <div>
                <span className="font-bold">
                  {translations.pages.myPagesPage.unit}:{" "}
                </span>
                {currentUnit.size} - {currentUnit.areaSize}m
                <span className="align-super text-[10.5px]">2</span>
              </div>
              <div>
                <span className="font-bold">
                  {translations.pages.myPagesPage.number}:{" "}
                </span>
                {currentUnit.unitNumber}
              </div>
              <div>
                <span className="font-bold">
                  {translations.general.price}:{" "}
                </span>
                {formatCurrency(currentUnit.price, translations)}
              </div>
              <div>
                <span className="font-bold">
                  {translations.general.insurance}:{" "}
                </span>
                {currentUnit.insurance.description} -{" "}
                {currentUnit.insurance.price} {translations.general.priceUnit}/
                {translations.general.shortMonth}
              </div>
            </div>
            <div className="bg-white rounded-xl p-6 lg:p-10">
              {/* YOUR FACILITY */}
              <h2>{translations.pages.changeStorage.yourFacility}</h2>
              <div>
                <span className="font-bold">
                  {translations.general.facility}:{" "}
                </span>
                {facility.name}
              </div>
              <div>
                <span className="font-bold">
                  {translations.general.streetAddress}:{" "}
                </span>
                {facility.streetAddress}
              </div>
              <div>
                <span className="font-bold">{translations.general.city}: </span>
                {facility.city}
              </div>
            </div>
          </div>
          <div className="grid gap-6 pt-6">
            <div>
              {/* STORAGE SIZE GROUPS */}
              <h2>{translations.pages.changeStorage.storageSizes}</h2>
              <SelectStorageSizes
                storageSizes={storageSizes}
                translations={translations}
                facilityId={facility.id}
                itemClassName="bg-white"
                currentSizeId={currentSizeId}
                onChange={handleChangeSizeGroup}
              />
            </div>
            {isLoadingMain && (
              <div className="bg-white rounded-xl p-6 lg:p-10 py-20">
                <Loader
                  loading={isLoadingMain}
                  useFullWindow={false}
                  useNoBackground={true}
                />
              </div>
            )}
            {!isLoadingMain && selectedSizeCodeId && availableGroupSizes && (
              <>
                <div className="bg-white rounded-xl p-6 lg:p-10">
                  {/* EXACT STORAGE SIZE */}
                  <h3>{translations.pages.changeStorage.storageSize}</h3>
                  <div>
                    {availableGroupSizes.map((groupSize, index) => (
                      <div
                        className="flex items-center mb-4 [&:not(:first-child)]:border-t border-gs-grey pt-4"
                        key={index}
                      >
                        <FormRadiobutton
                          name={`radio-${groupSize.sizeCodeId}`}
                          groupName="size-radio"
                          value={groupSize.sizeCodeId}
                          onChange={() =>
                            handleGroupRadioChange(groupSize.sizeCodeId)
                          }
                        >
                          <div className="flex text-base justify-between w-full">
                            <span>{groupSize.physicalSize} m²</span>
                            <span>{`${translations.general.fromShort} ${groupSize.priceInformation} ${translations.general.priceUnit}/${translations.general.shortMonth}`}</span>
                          </div>
                        </FormRadiobutton>
                      </div>
                    ))}
                  </div>
                </div>
                {selectedExactSizeSizeCodeId && (
                  <>
                    <div className="bg-white rounded-xl p-6 lg:p-10">
                      {/* FILTER */}
                      <h2>
                        {translations.pages.changeStorage.selectSpecificUnit}
                      </h2>
                      <div className="grid grid-cols-3 gap-10">
                        <div>
                          {/* WARM/COLD */}
                          <h3>
                            {translations.pages.changeStorage.storageType}
                          </h3>
                          {isLoadingFilters ? (
                            <Loader
                              loading={isLoadingFilters}
                              useFullWindow={false}
                              useNoBackground={true}
                            />
                          ) : (
                            <>
                              <FormRadiobutton
                                name="cold-radio"
                                groupName="warmcold-radio"
                                checked={selectedFeature === "cold"}
                                value="cold"
                                onChange={() => {
                                  setSelectedFeature("cold");
                                }}
                                isReversed={true}
                              >
                                {unitTranslations.outsideUnit}
                              </FormRadiobutton>
                              <FormRadiobutton
                                name="warm-radio"
                                groupName="warmcold-radio"
                                value="warm"
                                checked={selectedFeature === "warm"}
                                onChange={() => {
                                  setSelectedFeature("warm");
                                }}
                                isReversed={true}
                              >
                                {unitTranslations.heatedUnit}
                              </FormRadiobutton>
                            </>
                          )}
                        </div>
                        <div>
                          {/* FLOOR */}
                          <h3>{translations.pages.changeStorage.floor}</h3>
                          {isLoadingFilters ? (
                            <Loader
                              loading={isLoadingFilters}
                              useFullWindow={false}
                              useNoBackground={true}
                            />
                          ) : (
                            <>
                              <FormCheckbox
                                name="groundFloor-checkbox"
                                value="groundFloor"
                                checked={selectedFloor.includes("ground")}
                                onChange={() => {
                                  if (selectedFloor.includes("ground")) {
                                    setSelectedFloor(
                                      selectedFloor.filter(
                                        (floor) => floor !== "ground"
                                      )
                                    );
                                  } else {
                                    setSelectedFloor([
                                      ...selectedFloor.filter(
                                        (floor) => floor !== "elevatorFloor"
                                      ),
                                      "ground",
                                    ]);
                                  }
                                }}
                                isReversed={true}
                              >
                                {unitTranslations.groundFloor}
                              </FormCheckbox>
                              <FormCheckbox
                                name="elevatorFloor-checkbox"
                                value="elevatorFloor"
                                checked={selectedFloor.includes(
                                  "elevatorFloor"
                                )}
                                onChange={() => {
                                  if (selectedFloor.includes("elevatorFloor")) {
                                    setSelectedFloor(
                                      selectedFloor.filter(
                                        (floor) => floor !== "elevatorFloor"
                                      )
                                    );
                                  } else {
                                    setSelectedFloor([
                                      ...selectedFloor.filter(
                                        (floor) => floor !== "ground"
                                      ),
                                      "elevatorFloor",
                                    ]);
                                  }
                                }}
                                isReversed={true}
                              >
                                {unitTranslations.elevatorPlane}
                              </FormCheckbox>
                              <FormCheckbox
                                name="directAccess-checkbox"
                                value="directAccess"
                                checked={selectedFloor.includes("directAccess")}
                                onChange={() => {
                                  if (selectedFloor.includes("directAccess")) {
                                    setSelectedFloor(
                                      selectedFloor.filter(
                                        (floor) => floor !== "directAccess"
                                      )
                                    );
                                  } else {
                                    setSelectedFloor([
                                      ...selectedFloor,
                                      "directAccess",
                                    ]);
                                  }
                                }}
                                isReversed={true}
                              >
                                {unitTranslations.directAccess}
                              </FormCheckbox>
                            </>
                          )}
                        </div>
                        <div>
                          {/* SPECIFIC UNIT */}
                          <h3>
                            {translations.pages.changeStorage.specificUnit}
                          </h3>
                          {isLoadingFilters || isLoadingUnits ? (
                            <Loader
                              loading={isLoadingFilters}
                              useFullWindow={false}
                              useNoBackground={true}
                            />
                          ) : (
                            <>
                              <div className="grid grid-cols-4 text-center">
                                <label className="font-bold sticky bg-gs-white top-0 border-b border-gs-grey pt-5">
                                  {translations.pages.changeStorage.unit}
                                </label>
                                <label className="font-bold sticky bg-gs-white top-0 border-b border-gs-grey pt-5">
                                  {translations.pages.changeStorage.price}
                                </label>
                                <label className="font-bold sticky bg-gs-white top-0 border-b border-gs-grey pt-5">
                                  {translations.pages.changeStorage.vacantFrom}
                                </label>
                                <div className="sticky bg-gs-white top-0 border-b border-gs-grey pt-5"></div>
                                {isLoadingUnits ? (
                                  <Loader
                                    loading={isLoadingUnits}
                                    useFullWindow={false}
                                    useNoBackground={true}
                                  />
                                ) : (
                                  <div
                                    className={`col-span-4 ${styles.options}`}
                                  >
                                    {filteredUnits?.length === 0 ? (
                                      <div>
                                        {
                                          translations.pages?.order
                                            ?.noOtherUnitsAvailable
                                        }
                                      </div>
                                    ) : (
                                      filteredUnits?.map(
                                        (mappedUnit, index) => (
                                          <div
                                            key={index}
                                            className={`col-span-4 grid grid-cols-4 py-1 ${
                                              index % 2 === 0
                                                ? "bg-gs-green-50"
                                                : ""
                                            }`}
                                          >
                                            <div>{mappedUnit.unitNumber}</div>
                                            <div>{mappedUnit.price}</div>
                                            <div>{todaysDate}</div>
                                            <div>
                                              <FormCheckbox
                                                name={`unit-checkbox-${mappedUnit.unitNumber}`}
                                                value={mappedUnit.unitNumber}
                                                checked={
                                                  mappedUnit.unitNumber ===
                                                  selectedUnitNumber
                                                }
                                                onChange={() =>
                                                  handleUnitCheckboxChange(
                                                    mappedUnit
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        )
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    {selectedUnit && (
                      <div className="bg-white rounded-xl p-6 lg:p-10">
                        {/* DATE FOR CHANGE */}
                        <h2>
                          {translations.pages.changeStorage.chooseDateForChange}
                        </h2>
                        <Loader
                          loading={isLoadingDate}
                          useFullWindow={false}
                          useNoBackground={true}
                        />
                        {!isLoadingDate && selectedUnit && (
                          <div className="w-fit">
                            <FormSelect
                              options={dateOptions.map(
                                (dateOption) => dateOption.selectItem
                              )}
                              placeholder={translations.general.chooseDate}
                              label={translations.general.chooseDate}
                              name="select-date"
                              onChange={handleDateChange}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {selectedSizeCodeId &&
                      selectedExactSizeSizeCodeId &&
                      selectedUnit &&
                      selectedDate && (
                        <>
                          <div>
                            <h2>
                              {
                                translations.pages.changeStorage
                                  .updatedStorageChoice
                              }
                            </h2>
                            <div className="grid grid-cols-2 gap-6">
                              <div className="bg-white rounded-xl p-6 lg:p-10">
                                <div>
                                  <span className="font-bold">
                                    {translations.pages.myPagesPage.unit}: {""}
                                  </span>
                                  {selectedUnit.Size.GroupName} -{""}
                                  {selectedUnit.PhysicalSize}m
                                  <span className="align-super text-[10.5px]">
                                    2
                                  </span>
                                </div>
                                <div>
                                  <span className="font-bold">
                                    {translations.pages.myPagesPage.number}:{" "}
                                  </span>
                                  {selectedUnit.UnitNumber}
                                </div>
                                <div>
                                  <span className="font-bold">
                                    {translations.general.price}:{" "}
                                  </span>
                                  {formatCurrency(
                                    selectedUnit.MonthRate,
                                    translations
                                  )}
                                </div>
                                <div>
                                  <span className="font-bold">
                                    {
                                      translations.pages.changeStorage
                                        .accessionDate
                                    }
                                    :{" "}
                                  </span>
                                  {
                                    dateOptions.find(
                                      (dateOption) =>
                                        dateOption.date === selectedDate
                                    )?.selectItem.label
                                  }
                                </div>
                              </div>

                              <div className="grid grid-cols-2 gap-6 bg-white rounded-xl p-6 lg:p-10">
                                <div>
                                  <h3>{selectedUnit.Size.GroupName}</h3>
                                  <div>
                                    {
                                      translations.pages.changeStorage
                                        .choosenUnit.inA
                                    }
                                    <span className="highlight-dark-green">
                                      {" "}
                                      {selectedUnit.Size.GroupName}{" "}
                                    </span>
                                    {
                                      translations.pages.changeStorage
                                        .choosenUnit.storageYouCanFitApprox
                                    }
                                    <span className="highlight-dark-green">
                                      {" "}
                                      {
                                        sizeConfigurations.movingBoxes[
                                          selectedUnit.Size.GroupNameShort
                                        ]
                                      }{" "}
                                      {
                                        translations.pages.changeStorage
                                          .choosenUnit.movingBoxes
                                      }
                                    </span>
                                    .
                                  </div>
                                </div>
                                <div>
                                  <StorageSizeCardImage
                                    shortName={selectedUnit.Size.GroupNameShort}
                                  />
                                </div>
                              </div>

                              <div className="col-span-2 grid grid-cols-2 gap-6 bg-white rounded-xl p-6 lg:p-10">
                                <div>
                                  <FormCheckbox
                                    name="integration-policy-checkbox"
                                    checked={integrationPolicyChecked}
                                    onChange={(e) =>
                                      setIntegrationPolicyChecked(
                                        e.target.checked
                                      )
                                    }
                                  >
                                    {translations.pages?.order?.integrityPolicy}
                                    <LinkElement
                                      href={integrityPolicyLink.url}
                                      title={
                                        translations.pages?.order
                                          ?.integrityPolicyLinkText
                                      }
                                      className="ml-1"
                                      linkSize={eLinkSize.Large}
                                      target={eTarget.Blank}
                                    >
                                      {
                                        translations.pages?.order
                                          ?.integrityPolicyLinkText
                                      }
                                    </LinkElement>
                                    .
                                  </FormCheckbox>
                                  <FormCheckbox
                                    name="general-policy-checkbox"
                                    checked={generalPolicyChecked}
                                    onChange={(e) =>
                                      setGeneralPolicyChecked(e.target.checked)
                                    }
                                  >
                                    {
                                      translations.pages?.order
                                        ?.approvalInsurance
                                    }
                                    <LinkElement
                                      href={insuranceTermsLink.url}
                                      title={
                                        translations.pages?.order
                                          ?.approvalInsuranceLinkText
                                      }
                                      className="ml-1"
                                      linkSize={eLinkSize.Large}
                                      target={eTarget.Blank}
                                    >
                                      {
                                        translations.pages?.order
                                          ?.approvalInsuranceLinkText
                                      }
                                    </LinkElement>
                                    .
                                  </FormCheckbox>
                                </div>
                                <div>
                                  <FormCheckbox
                                    name="approval-policy-checkbox"
                                    checked={approvalPolicyChecked}
                                    label={
                                      translations.pages?.order
                                        ?.approvalRightOfWithdrawal
                                    }
                                    onChange={(e) =>
                                      setApprovalPolicyChecked(e.target.checked)
                                    }
                                  />
                                  {isCompany && (
                                    <FormCheckbox
                                      name="company-policy-checkbox"
                                      checked={companyPolicyChecked}
                                      label={
                                        translations.pages?.order
                                          ?.approvalRightToSignForCompany
                                      }
                                      onChange={(e) =>
                                        setCompanyPolicyChecked(
                                          e.target.checked
                                        )
                                      }
                                    />
                                  )}
                                  <LinkElement
                                    title={
                                      translations.pages.changeStorage
                                        .changeStorage
                                    }
                                    buttonColorTheme={eButtonColorTheme.Green}
                                    chevronPosition={eChevronPosition.None}
                                    isButton={true}
                                    onClick={handleSubmit}
                                    className="w-full mt-6 uppercase"
                                    disabled={
                                      !integrationPolicyChecked ||
                                      !generalPolicyChecked ||
                                      !approvalPolicyChecked ||
                                      (isCompany && !companyPolicyChecked)
                                    }
                                  >
                                    {
                                      translations.pages.changeStorage
                                        .changeStorage
                                    }
                                  </LinkElement>
                                  <Loader
                                    loading={isLoadingSend}
                                    useFullWindow={true}
                                    useNoBackground={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
