import { iRichText } from '@/app/models/richText';

interface Props {
    text: iRichText;
    className?: string;
}


export default function RichText({ text, className }: Props) {
    return (
        <>
            {text && (
                <div className={`richtext ${className ? className : ""}`} dangerouslySetInnerHTML={{ __html: text.markup }}></div>
            )}
        </>
    );
}
