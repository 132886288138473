import { StateCreator } from "zustand";

export type ValidationState = {
  validation: {
    formValidated: boolean;
    termsAccepted: boolean;
    errors: string[];
    isAllowedRedirect: boolean;
  };
};

export type ValidationActions = {
  setValidationValue: (
    key: keyof ValidationState["validation"],
    value: any
  ) => void;
  resetValidation: () => void;
};

const initialState: ValidationState = {
  validation: {
    formValidated: false,
    termsAccepted: false,
    errors: [],
    isAllowedRedirect: false,
  },
};

export const createValidationSlice: StateCreator<
  ValidationState & ValidationActions
> = (set) => ({
  ...initialState,
  setValidationValue: (key, value) =>
    set((state) => ({
      validation: {
        ...state.validation,
        [key]: value,
      },
    })),
  resetValidation: () => set(() => initialState),
});
