"use client";

import React, { useEffect, useState } from "react";
import styles from "./facilityandareacampaignitem.module.scss";
import { iDiscount } from "@/app/models/apiModels/Sizes/discount";
import Loader from "../Loader/Loader";
import { iFacilityPage } from "@/app/models/pageTypes/facilityPage";
import { iGetDiscountBySiteRequestModel } from "@/app/models/apiModels/requestModels/getDiscountBySiteRequestModel";
import { iAreaPage } from "@/app/models/pageTypes/areaPage";
import { iGetDiscountByAreaRequestModel } from "@/app/models/apiModels/requestModels/getDiscountByAreaRequestModel";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";

export interface Props {
    pageData: iFacilityPage | iAreaPage;
    translations: any;
    country: string;
    className?: string;
    previewMode: boolean;
    language: string;
}

export default function FacilityAndAreaCampaignItem({ pageData, country, translations, language, previewMode, className = "" }: Props) {
    const [bestDiscount, setBestDiscount] = useState<iDiscount | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchAndSetBestDiscount = async () => {
            if (pageData.contentType === "facilityPage") {
                const discount = await fetchBestDiscountByFacility();
                setBestDiscount(discount);
            } else if (pageData.contentType === "areaPage") {
                const discount = await fetchBestDiscountByArea();
                setBestDiscount(discount);
            }

            setIsLoading(false);
        };

        fetchAndSetBestDiscount();
    }, []);

    const fetchBestDiscountByFacility = async () => {
        const requestModel: iGetDiscountBySiteRequestModel = {
            country: country,
            siteId: (pageData as iFacilityPage).properties.facilityID
        }

        try {
            const response = await fetch('/api/campaign/getDiscountBySite', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestModel),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const discount = data.content as iDiscount;

            return discount;
        } catch (error) {
            clientLogError("FacilityAndAreaCampaignItem.tsx", "fetchBestDiscountByFacility", error);
            return null;
        }
    };

    const fetchBestDiscountByArea = async () => {
        const requestModel: iGetDiscountByAreaRequestModel = {
            country: country,
            areaId: (pageData as iAreaPage).properties.areaId,
            pageId: (pageData as iAreaPage).id,
            previewMode: previewMode,
            language: language
        }

        try {
            const response = await fetch('/api/campaign/getDiscountByArea', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestModel),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const discount = data.content as iDiscount;

            return discount;
        } catch (error) {
            clientLogError("FacilityAndAreaCampaignItem.tsx", "fetchBestDiscountByArea", error);
            console.error('FacilityAndAreaCampaignItem.tsx - Fetch error:', error);
            return null;
        }
    };

    return (
        <div className={`rounded-3xl card-item flex flex-col h-full`}>
            <a href={pageData.route.path} className={`relative rounded-3xl bg-light-gs-green ${className}  flex flex-col h-full`}>
                <div className="relative  flex flex-col h-full">
                    <div className={`p-10 pb-4 flex flex-col flex-grow ${styles.titleContainer}`}>
                        {pageData.contentType === "facilityPage" && (pageData as iFacilityPage).properties.city && (
                            <div className="h4 mb-0-i">{(pageData as iFacilityPage).properties.city}</div>
                        )}
                        <h3 className="mb-1-i">
                            {`${pageData.contentType === "facilityPage" ? (pageData as iFacilityPage).properties.facilityName : ""}`}
                            {`${pageData.contentType === "areaPage" ? (pageData as iAreaPage).properties.areaName : ""}`}
                        </h3>
                        {pageData.contentType === "facilityPage" && (pageData as iFacilityPage) && (
                            <div className="text-normal mb-0-i">
                                {(pageData as iFacilityPage).properties.streetAddress}
                            </div>
                        )}
                    </div>
                    <div className="text-normal mb-0-i discount-area flex flex-col h-[112px]">
                        {bestDiscount?.firstMonthDiscount ? (
                            <div>
                                <div className="text-l">{translations.pages.facilityPage.rightNow}</div>
                                <div className="text-4xl">{translations.pages.campaignListingPage.firstMonthDiscountPrice}</div>
                                <div className="text-l">{translations.pages.campaignListingPage.firstMonthDiscountText}</div>
                            </div>
                        ) : (
                            <div>
                                <div className="text-l">{translations.pages.facilityPage.rightNow}</div>
                                <div className="text-4xl">{`${translations.general.fromShort} ${bestDiscount?.discountPrice} `}</div>
                                <div className="text-l">{`${translations.general.priceUnit}/${translations.general.shortMonth}`}</div>
                            </div>
                        )}
                    </div>
                    <div className="p-10 pt-4 flex flex-col">
                        <div className={`${!bestDiscount?.firstMonthDiscount ? styles.ordinaryPrice : ""} text-center text-2xl`}>
                            {`${translations.general.fromShort} ${bestDiscount?.ordPrice} ${translations.general.priceUnit}/${translations.general.shortMonth}`}
                        </div>
                        <div className="mt-4 mb-0-i textSmall">
                            <div className="text-center font-semibold">{translations.pages.campaignListingPage.isAdded}</div>
                            <div>{translations.pages.campaignListingPage.insuranceText}</div>
                        </div>
                    </div>
                </div>
            </a>
            <Loader loading={isLoading} />
        </div >
    );
}
