import { useState } from "react";
import Loader from "../Loader/Loader";
import { logError, logInfo } from "@/app/helpers/azureBlobLogger";

interface Props {
    country: string;
    translations: any;
    gdprText: string;
    onClose: () => void;
}

export default function GDPR({ country, translations, gdprText, onClose }: Props) {
    const [loading, setLoading] = useState<boolean>(false);
    const [requestSent, setRequestSent] = useState<boolean>(false);

    const onSubmit = async (type: string) => {
        setLoading(true);
        try {
            logInfo('GDPR.tsx - Sending GDPR request', 'type', type);
            const response = await fetch('/api/user/sendGDPRRequest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Country': country, // Include country in the headers
                },
                body: JSON.stringify({ type }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setRequestSent(true);
            // Handle success (e.g., show a success message)
        } catch (error) {
            logError("GDPR.tsx - Fetch error:", error);
            //TODO: Handle error (e.g., show an error message)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <Loader loading={loading} useFullWindow={true} />
            <h2>{translations.general.gdpr.title}</h2>
            <div className="richtext" dangerouslySetInnerHTML={{ __html: gdprText }}></div>
            {requestSent && (
                <div className="italic">{translations.general.gdpr.requestSent}</div>
            )}
            <div className="flex flex-col gap-4">
                <button className='btn w-full' onClick={() => onSubmit('s')}>{translations.general.gdpr.stopProcessing}</button>
                <button className='btn w-full' onClick={() => onSubmit('r')}>{translations.general.gdpr.requestRemoval}</button>
                <button className='btn w-full' onClick={() => onSubmit('e')}>{translations.general.gdpr.requestExcerpts}</button>
                <button className='btn w-full dark-contrast' onClick={onClose}>{translations.general.close}</button>
            </div>
        </div>
    );
}
