import { NormalPayment, PaymentRow } from "@/app/models/apiModels/payment";
import { PaymentType } from "@/app/models/enum/paymentType";
import React from "react";

interface PaymentDetailsProps {
  title: string;
  priceUnit: string;
  isCompany: boolean;
  paymentRows?: PaymentRow[];
  translation: any;
}

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  title,
  priceUnit,
  isCompany = false,
  paymentRows,
  translation,
}) => (
  <div>
    {title && <h4 className="mt-4">{title}</h4>}
    <div className="w-full grid grid-cols-3">
      {paymentRows &&
        paymentRows.map(
          (row, index) =>
              <React.Fragment key={index}>
                <p className="font-bold">
                  {row.type === PaymentType.Rent && translation.pages.order?.paymentContainer.rent}
                  {row.type === PaymentType.Insurance && translation.pages.order?.paymentContainer.insurance}
                  {row.type === PaymentType.Discount && translation.pages.order?.paymentContainer.discount}
                  {row.type === PaymentType.Rounding && translation.pages.order?.paymentContainer.rounding}
                </p>
                {row.type != PaymentType.Total ? (
                  <>
                    {row.from && (
                      <p className="text-center">{`${row.from} - ${row.to}`}</p>
                    )}
                    <p className={`text-end ${!row.from ? "col-start-3" : ""}`}>
                      {`${row.total.toFixed(2)} ${priceUnit} ${
                        isCompany && row.type === PaymentType.Rent
                          ? translation.pages.order?.paymentContainer.excludingVat
                          : ""
                      }`}
                    </p>
                  </>
                ) : (
                  <>
                    <div className="col-span-3 inline-flex w-full font-bold justify-between border-y border-gs-grey">
                      <p className="my-1">{translation.pages.order?.paymentContainer.total}</p>
                      <p className="my-1">
                        {`${row.total.toFixed(2)} ${priceUnit}`} {isCompany && translation.pages.order?.paymentContainer.excludingVat}
                      </p>
                    </div>
                  </>
                )}
              </React.Fragment>
        )}
    </div>
  </div>
);
