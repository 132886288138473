"use client";
import UnitInformation from "@/app/components/Order/UnitInformation";
import { formatDateToLocal } from "@/app/helpers/dateHelper";
import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";

interface OrderStoragePageProps {
  pageData: iOrderStoragePage;
}

export default function OrderStorageStep2ReviewYourInfo({
  pageData,
}: OrderStoragePageProps) {
  let translation = pageData.requestContext.translations;
  const siteData = pageData.properties.site;
  const siteUnit = pageData.properties.unit;
  const unitFeatures = pageData.properties.unitFeatures;

  const order = doUseOrderStorageStore(useShallow((state) => state));

  useEffect(() => {


    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };
    const handelPageHide = () => {
      fetch(`/api/unit/unReserveUnit`, {
      keepalive: true,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Country: pageData.requestContext.country,
      },
      body: JSON.stringify({
        unitId: siteUnit?.Id,
        sizeId: siteUnit?.SizeId,
      }),
    });
  }
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("pagehide", handelPageHide);

    return () => {
      window.removeEventListener("pagehide", handelPageHide);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="px-6 lg:px-0">
      <div className="justify-items-center bg-gs-white rounded-3xl p-6 lg:p-10 border border-gs-black-300">
        <div className="w-full">
          <div className="inline-flex justify-between w-full">
            <h3 className="text-md font-bold">
              {translation.general?.storageUnit ?? "Storage"}
            </h3>
          </div>

          <div className="flex justify-between pt-2 pb-6">
            <div className="inline-flex items-center">
              <img
                src={`/assets/img/storageSizes/storage_${siteUnit?.Size.GroupNameShort}.png`}
                alt="Green Storage"
                width="48"
                height="48"
              />
              <h3 className="font-bold uppercase p-4">
                {siteUnit?.Size?.GroupName}
              </h3>
            </div>
            <div className="inline-flex items-center">
              <p>{order.unit.unitSizeM2} m²</p>
            </div>
          </div>

          {siteUnit && unitFeatures && (
            <div className="bg-gs-grey rounded-lg p-6">
              <p className="font-bold">
                {translation.pages?.order?.storageDescription ??
                  "Storage information"}
                :
              </p>
              <UnitInformation translation={translation} pageData={pageData} />
            </div>
          )}

          <div className="pt-6">
            <h4 className="font-bold">{siteData?.name}</h4>
            <p>
              {siteData?.address1}, {siteData?.town}
            </p>
          </div>

          <div className="pt-6">
            <h4 className="font-bold">{translation.pages?.order.startDate}</h4>
            <p>
              {formatDateToLocal(
                new Date(order.date.startDate).toISOString(),
                pageData.properties.region
              )}
            </p>
          </div>

          <div className="pt-6">
            <h4 className="font-bold">{translation.pages?.order.insurance}</h4>
            <input
              type="text"
              readOnly
              value={`${order.insurance.insuranceSize} ${order.insurance.insurancePricePerMonth} ${translation.general.priceUnit}/${translation.general.shortMonth} - ${order.insurance.insuranceDescription}`}
              aria-haspopup="listbox"
              aria-disabled={false}
            />
          </div>
        </div>
      </div>

      <div className=" justify-items-center bg-gs-white rounded-3xl p-6 lg:p-10 mt-8">
        <div className="w-full">
          <div className="inline-flex justify-between w-full">
            <h3 className="text-md font-bold">
              {translation.pages?.order?.yourInformation ?? "Your information"}
            </h3>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div>
              <p className="font-bold">
                {translation.pages?.order.form.personalNumber ??
                  "personalnumber"}
              </p>
              <p>{order.user.personalNumber}</p>
            </div>
            <div>
              <p className="font-bold">
                {translation.pages?.order.form.email ?? "Email"}
              </p>
              <p>{order.user.email}</p>
            </div>
            <div>
              <p className="font-bold">
                {translation.general.streetAddress ?? "Street address"}
              </p>
              <p>{order.user.streetAddress}</p>
              <p>
                {order.user.postalCode} {order.user.city}
              </p>
            </div>
            <div>
              <p className="font-bold">
                {translation.pages?.order.form.phoneNumber ?? "Phone number"}
              </p>
              <p>{order.user.phoneNumber}</p>
            </div>
            {order.user.isCompany && (
              <div>
                <p className="font-bold">
                  {translation.pages?.order.form.companyName ?? "Company name"}{" "}
                  &{" "}
                  {translation.pages?.order.form.organisationsNumber ??
                    "Org.nummer"}
                </p>
                <p>{order.user.companyName}</p>
                <p>{order.user.companyOrgNumber}</p>
              </div>
            )}
            {order.user.isCompany && order.user.isInvoice && (
              <>
                <div>
                  <p className="font-bold">
                    {translation.pages?.order.form.invoiceEmail ??
                      "Faktura email"}
                  </p>
                  <p>{order.user.invoiceEmail}</p>
                </div>
                <div>
                  <p className="font-bold">
                    {translation.pages?.order.form.invoiceRef ??
                      "Faktura referens"}
                  </p>
                  <p>{order.user.invoiceReference}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
