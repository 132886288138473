'use client';

import { iStorageSize } from '@/app/models/storageSize';
import styles from './selectstoragesizes.module.scss';
import { useState } from 'react';
import { clientLogError } from '@/app/helpers/clientLoggingHelper';
import StorageSizeCardImage from '../StorageSizeCardImage/StorageSizeCardImage';

interface Props {
    storageSizes: iStorageSize[];
    facilityId?: string;
    translations: any;
    className?: string;
    itemClassName?: string;
    currentSizeId: number
    onChange: (sizeCodeId: string, sizeId: number) => void;
}

export default function SelectStorageSizes({
    storageSizes,
    facilityId,
    translations,
    className,
    itemClassName,
    currentSizeId,
    onChange
}: Props) {
    try {
        if (!storageSizes || storageSizes.length === 0) {
            clientLogError("SelectStorageSizes.tsx", "Error in SelectStorageSizes", "No storage sizes found", "facilityId", facilityId);
            storageSizes = [];
        }

        const [selectedSizeId, setSelectedSizeId] = useState<number | null>(currentSizeId);

        const handleSelectClick = (sizeCodeId: string, sizeId: number) => {
            setSelectedSizeId(sizeId);
            onChange(sizeCodeId, sizeId);
        };

        return (

            <div className="grid grid-cols-2 gap-6 md:grid-cols-4">
                {storageSizes.map((storage) => (
                    <div
                        onClick={() => handleSelectClick(storage.sizeCodeId, storage.id)}
                        className={`no-underline cursor-pointer text-center block rounded-3xl border border-gs-grey p-4 hover:border-gs-black-500 focus:outline-none ${itemClassName ? itemClassName : ""} ${selectedSizeId === storage.id ? styles.selected : ""}`}
                        key={storage.id}>
                        <h3 className="mb-0">{storage.name}</h3>
                        <p className="textSmall font-bold mb-2-i">
                            {storage.size} m
                            <span className="align-super text-[10.5px]">2</span>
                        </p>
                        <div className={`text-normal mb-6-i ${(storage.specialPrice && storage.specialPrice > 0 && !storage.firstMonthDiscount) ? "line-through" : ""}`}>{` ${translations.general.fromShort} ${storage.price} ${translations.general.priceUnit}/${translations.general.shortMonth} `}</div>
                        {((storage.specialPrice && storage.specialPrice > 0) || storage.firstMonthDiscount) && (
                            <div className={`${styles.specialPriceLabelContainer}`}>
                                <div className={`description ${styles.specialPriceLabel}`}>
                                    {storage.firstMonthDiscount && (
                                        <>{translations.pages.facilityPage.cheapFirstMonth}</>
                                    )}
                                    {!storage.firstMonthDiscount && storage.specialPrice && storage.specialPrice > 0 && (
                                        <>
                                            <span className="font-bold">{translations.pages.facilityPage.rightNow}</span>
                                            {` ${translations.general.fromShort} ${storage.specialPrice} ${translations.general.priceUnit}/${translations.general.shortMonth}`}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="inline-block rounded-lg bg-gray-50 mb-4 ">
                            <StorageSizeCardImage shortName={storage.shortName} />
                        </div>
                        <p className="textSmall mb-0-i">{storage.movingBoxesCount} {translations.pages.order.movingBoxes}</p>
                    </div>
                ))}
            </div>
        );
    }
    catch (error) {
        clientLogError("Error in SelectStorageSizes", 'Error when generating StorageSizes', "Data",
            "facilityId", facilityId,
            "translations", translations,
            "Error:", error);
    }
}


