import { StateCreator } from "zustand";

export type PromotionState = {
  promotion: {
    promotionCode: string;
  };
};

export type PromotionActions = {
  setPromotionValue: (
    key: keyof PromotionState["promotion"],
    value: any
  ) => void;
  resetPromotion: () => void;
};

const initialState: PromotionState = {
  promotion: {
    promotionCode: "",
  },
};

export const createPromotionSlice: StateCreator<
  PromotionState & PromotionActions
> = (set) => ({
  promotion: {
    promotionCode: "",
  },
  setPromotionValue: (key, value) =>
    set((state) => ({
      promotion: {
        ...state.promotion,
        [key]: value,
      },
    })),
  resetPromotion: () => set(() => initialState),
});
